// All shops wrapper
.allShopsWrapper {
  margin-left: 25vh;
  margin-right: 25vh;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .allShopsWrapper {
    margin-left: 0vh;
    margin-right: 0vh;
  }
}
