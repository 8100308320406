.shopTemplateWrapper{
    font-family: var(--mavenpro);
    font-size: 15px;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    border-radius:  5px;
    width: 100%;
    margin-right: 25px;
    margin-top: 25px;
    position: relative;
    margin-bottom: 15px;
    .shopNameConfWrapper{
        display: flex;
        flex-direction:row;
        position: relative;
        width: 100%;
        .imgConfWrapper{
            display: flex;
            flex-direction: row;
            position: relative;
            // width: 100%;
            .shopImgEdit{
                position: absolute;
                z-index: 100;
                margin-left: 10px;
                margin-top: 10px;
                background-color: white;
                width: 30px;
                height: 30px;
                text-align: center;
                border-radius: 50%;
                padding-top: 3.5px;
                cursor: pointer;
            }
        }
        .shopImgWrapper{
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            // padding: 12px;
            border: none;
            border-radius: 5px;
            width: 450px;
            height: 250px;
            // bottom:0;
            // left: 0;
            // top: 0;
            background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
            overflow: hidden;
            position: relative;
            // margin: auto;
            .shopImg{
                width: 450px;
                height: auto;
            }
            .shopIcn{   
                border-radius: 5px;
                position: absolute;
                top: 50%;
                margin-left: 180px;
                margin-top: -18px;
            }
        }
        .shopInfoConfiguration{
            display: flex;
            flex-direction: column;
            margin-left: 75px;
            color: black;
            position: relative;
            width: 100%;
            .shopName{
                margin-top: 14.5px;
                font-size: 22px;
                font-weight: 500;
                font-family: var(--mavenpro);
            }
            .extraInformationWrapper{
                margin-top: 8px;
                .selfServiceEnabled{
                    border: none;
                    border-radius: 25px;
                    padding: 5px;
                    color: white;
                    background-color: black;
                    font-size: 12px;
                    width: fit-content;
                }
                .selfServiceDisabled{
                    border: none;
                    border-radius: 25px;
                    padding: 5px;
                    color: white;
                    background-color: grey;
                    font-size: 12px;
                    width: fit-content;
                }
            }
            .shopDescription{
                margin-top: 18px;
                margin-bottom: 15px;
                font-size: 16.5px;
                width: 60%;
                font-weight: 400;
                color: grey;
            }
            .confWrapper{
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                font-weight: 400;
                // margin-top: 100px;
                position: absolute;
                bottom: 0;
                margin-bottom: 25px;
                right: 0;
                margin-right: 45px;
                margin-top: 15px;
                .placeOrderText{
                    // text-decoration: underline;
                    cursor: pointer;
                    font-size: 15px;
                    background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
                    border-radius: 5px;
                    padding: 5px;
                    color: white;
                    margin-top: auto;
                    margin-bottom: auto;
                    width: 125px;
                    height: 35px;
                    font-weight: 400;
                    text-align: center;
                }
                .viewOrderText{
                    font-size: 15px;
                    margin-left: 25px;
                    cursor: pointer;
                    margin-top: auto;
                    margin-bottom: auto;
                    font-weight: 400;
                    width: 125px;
                    height: 35px;
                    background-image: var(--backgroundImg);
                    border-radius: 5px;
                    padding: 5px;
                    color: white;
                    text-align: center;
                }
                // }
            }
        }
    }

    .shopTaxCatWrapper{
        margin-top: 17.5px;
        display: flex;
        flex-direction: row;
        font-size: 15px;
        .taxHeader{
            font-weight: 500;
        }
        .taxCat{
            margin-left: 12px;
        }
    }
    .shopEnabledServiceWrapper{
        margin-top: 7.5px;
        display: flex;
        flex-direction: row;
        font-size: 15px;
        .serviceHeader{
            font-weight: 500;
        }
        .service{
            margin-left: 12px;
        }
    }
}
.sep{
    border-top: 1px solid #DDDDDD;
}



// dropdown button
.shopTemplateConfigurationWrapper{
    position: absolute;
    margin-top: 12px;
    margin-left: 400px;
    // margin-left: -45px;
    .shopConfBtn{
        border: none;
        background-color: transparent;
        color: black;
        text-decoration: underline;
        font-size: 15px;
        cursor: pointer;
        font-weight: 500;
        display:flex;
        flex-direction: row;
        // background-color: #1AB394;
        // border-radius: 8px;
        // padding: 5px;
        // color: white;
        margin-top: auto;
        margin-bottom: auto;
        .btnName{
            margin-top: auto;
            margin-bottom: auto;
            .settingsIcon:after{
                transform: rotate(-270deg);
                transition: height 0.3s linear,
                width 0.3s linear,
                left 0.3s linear,
                top 0.3s linear,
                transform 0.3s linear,
                background-color 0.3s linear;
            }

        }
        .btnIcon{
            margin-left: 1.5px;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    .shopConfDropDownWrapper{
        display: flex;
        flex-direction: column;
        font-size: 13.5px;
        background-color: white;
        position: absolute;
        border-radius: 12px;
        z-index: 1;
        height: fit-content;
        width: 175px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
        padding-top: 12px;
        padding-bottom: 10px;
        .shopConfListWrapper{
            font-family: var(--mavenpro);
            font-weight: 450px;
            color: rgb(0, 0, 0);
            margin-left: -1.5px;
            margin-bottom: 10px;
            .shopConfList{
                margin-top: 10px;
                font-size: 13.5px;
                list-style: none;
                cursor: pointer;
                font-family: var(--mavenpro);
                
            }
        }
    }
}


// modal
.confirmationModal{
    border-radius: 15px;
    font-family: var(--mavenpro);
    padding: 25px;
    height: fit-content;
    width: 350px;
    overflow: hidden;
    color: black;
    .confirmationTxt{
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        margin-top: 15px;
    }
    .confirmationBtns{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;
        .cncl{
            border: none;
            font-family: var(--mavenpro);
            font-size: 15px;
            font-weight: 500;
            text-decoration: underline;
            background-color: transparent;
        }
        .confirmDeleteBtn{
            border: none;
            border-radius: 8px;
            background-color: black;
            color: white;
            font-family: var(--mavenpro);
            font-size: 15px;
            font-weight: 500;
            padding: 7px;
        }
    }
}

.confirmationModal::-webkit-scrollbar{
    display: none;
}


