.roomStatusWrapper{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    border: none;
    border-radius: 12px;
    padding: 15px;
    font-size: 15px;
    width: 215px;
    // height: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 25px;
    .roomInfoWrapper{
        display: flex;
        flex-direction: row;
        width: 100%;
        position: relative;
        .roomNumber{
            font-weight: 500;
            font-size: 27px;
        }
        .ribbon{
            height: 30px;
            width: 22px;
            // background-color: red; 
            border-radius: 0px 0px 3px 3px;
            margin-top: -20px;
            margin-left: 125px;
        }
    }
    .roomStatus{
        background-color: transparent;
        color: black;
        border: none;
        border-radius: 15px;
        font-size: 14px;
        margin-top: 5px;
        width: fit-content;
        padding:2.5px 5px 2.5px 5px;
    }
    .horizontalSeparator{
        width: 100%;
        border-bottom: 0.5px solid black;
        margin-top: 12px;
    }
    .changeStatusWrapper{
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        .checkboxStatus{
            width: 20px;
            height: 20px;
        }
        .statusText{
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 12px;
            font-size: 15.5px;
            font-weight: 400;
        }
    }
}