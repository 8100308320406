.advPortfolioSummaryWrapper{
    font-family: var(--mavenpro);
    height:fit-content;
    padding: 25px;
    background-color: white;
    position: relative; 
    overflow: hidden;
    width: 100%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border-radius: 12px;
    .advFolioHeader{
        text-align: center;
        font-size: 17.5px;
        font-weight: 500;
        margin-bottom: 15px;
        width: 100%;
        position: relative;
    }
    .horizontalSeparator{
        position: relative;
        width: 100%;
        border-top: 1px solid rgb(189, 182, 182);
        background-color: transparent;
    }
    .summary{
        margin-top: 20px;
        margin-bottom: 1.5px;
        background-color: transparent;
        margin-bottom: 25px;
        .summaryItems{
            display: flex;
            flex-direction: column;
            .summaryItem{
                // display: grid;
                // grid-template-columns: 250px auto;
                display: inline-block;
                margin-bottom: 17.5px;
                .summaryHeader{
                    font-size: 14.5px;
                    margin-top: auto;
                    // width: 250px;
                    margin-bottom: auto;
                    float: left;
                }
                .summaryVal{
                    font-size: 14.5px;
                    margin-top: auto;
                    margin-bottom: auto;
                    // text-align: left;
                    float: right;
                    // margin-left: 85px;
                }
            }
        }
        .horizontalSeparator{
            width: 100%;
            border-top: 1px solid rgb(189, 182, 182);
            background-color: transparent;
        }
        .taxesBreakdownWrapper{
            margin-top: 15px;
            .taxesHeader{
                font-size: 17.5px;
                font-weight: 500;
                text-decoration: underline;
                background-color: transparent;
                margin-top: 25px;
                margin-bottom: 20px;
            }
            .taxesBreakdownItems{
                display: flex;
                flex-direction: column;
                margin-top: 17.5px;
                .taxesBreakdownItem{
                    // display: grid;
                    // grid-template-columns: 305px auto;
                    display: inline-block;
                    background-color: transparent;
                    margin-bottom: 17.5px;
                    // font-weight: 500;
                    width: 100%;
                    .taxesBreakdownHeader{
                        font-size: 14.5px;
                        margin-top: auto;
                        margin-bottom: auto;
                        float: left;
                    }
                    .taxesBreakdownVal{
                        font-size: 14.5px;
                        margin-top: auto;
                        margin-bottom: auto;
                        float: right;
                    }
                }
            }
        }
    }
}