.charterInsightsWrapper{
    font-family: var(--mavenpro);
    // display: grid;
    // grid-template-columns: auto auto;
    display: flex;
    flex-direction: column;
    color: black;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-right: 90px;
    .insightHeading{
        margin-top: -5px;;
        font-weight: 400;
        font-size: 14px;
        font-weight: 400;
        color: rgb(121, 116, 116);
    }
    .insightNumber{
        font-weight: 400;
        font-size: 75px;
    }
}