.houseKeepingRoomUpdatedWrapper {
  font-family: var(--mavenpro);
  padding: 17.5px;
  border: none;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 215px;
  margin-bottom: 25px;
  margin-right: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  //   box-shadow: rgba(13, 13, 23, 0.1) 0px 8px 24px,
  //     rgba(13, 13, 23, 0.1) 0px 16px 56px, rgba(13, 13, 23, 0.1) 0px 24px 80px;
  .ribbonWrapper {
    position: absolute;
    // background-color: red;
    height: 27.5px;
    width: 17.5px;
    top: 0;
    margin-top: -5px;
    border: none;
    border-radius: 2.5px;
    right: 0;
    margin-right: 25px;
  }
  .roomInfoWrapper {
    // margin-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .roomNoWrapper {
      margin-top: auto;
      margin-bottom: auto;
      font-size: 27.5px;
      font-weight: 500;
    }
    .roomOccupiedStatus {
      margin-left: 12px;
      margin-top: auto;
      margin-bottom: auto;
      overflow: hidden;
      height: 25px;
      width: 22px;
      .roomUserImg {
        height: 22px;
        width: 22px;
      }
    }
  }
  .cleanRoomStatus {
    margin-top: 17.5px;
    display: flex;
    flex-direction: row;
    .cleanStatusChecked {
      margin-top: auto;
      margin-bottom: auto;
      height: 17.5px;
      width: 17.5px;
      border-radius: 5px;
    }
    .cleanStatus {
      margin-left: 12px;
      margin-top: auto;
      margin-bottom: auto;
      font-family: var(--mavenpro);
      font-size: 15.5px;
      font-weight: regular;
    }
  }
}
