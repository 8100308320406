// Table view
.categoriesWrapper {
  position: relative;
  margin-top: 22px;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  height: calc(100vh-175px);
}
.categoriesWrapper::-webkit-scrollbar {
  display: none;
}
.menuItemsWrapper {
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  width: 150px;
  height: 75px;
  cursor: pointer;
}
.menuItemsWrapper::-webkit-scrollbar {
  display: none;
}

// Menu items --- Order
.orderWrapper {
  position: relative;
  width: 100%;
  height: 87.5%;
  overflow-y: scroll;
}

.orderWrapper::-webkit-scrollbar {
  display: none;
}
