.tabsContentWrapper{
    font-family: var(--mavenpro);
    font-size: 13px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 15px;
    margin-right: 15px;
    .tabItem{
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        .tabImage{
            overflow: hidden;
            padding: 2.5px;
            .tabImg{
                height: 45px;
                width: 45px;
            }
        }
    }
}