.auditReportWrapper{
    font-family: var(--mavenpro);
    .infoWrapper{
        display: flex;
        margin-bottom: 15px;
        flex-direction: row;
        width: 100%;
        position: relative;
        flex-wrap: wrap;
        .displayCalendarBtn{
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: fit-content;
            padding: 5px;
            border: 1px solid #DDDDDD;
            background-color: transparent;
        }
        .calendarFilterDisplay{
            border: 1px solid black;
            // padding: 12px;
            font-size: 15px;
            color: black;
            cursor: pointer;
            border-radius: 25px;
            width: 175px;
            height: 35px;
            text-align: center;
            padding-top: 5px;
        }
        .calendarFilter{
            border: none;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            padding: 25px;
            display: flex;
            flex-direction: column;
            position: absolute;
            height: fit-content;
            margin-top: 45px;
            z-index: 100;
            border-radius: 12px;
            background-color: white;
            .flashManagerCalendar{
                border-radius: 12px;
                font-family: var(--mavenpro);
                font-size: 15.5px;
                border: none;
                // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }
            .buttonWrappers{
                // display: flex;
                // flex-direction: row;
                // justify-content: center;
                .cancelButton{
                    margin-top: 25px;
                    .cancelBtn{
                        border: none;
                        border-radius:8px;
                        font-family: var(--mavenpro);
                        font-size: 14.5px;
                        width: 125px;
                        height: 45px;
                        background-color: red;
                        color: white;
                    }
                }
            }
            .applyButton{
                margin-top: 25px;
                margin-left: 25px;
                .applyBtn{
                    border: none;
                    border-radius:8px;
                    font-family: var(--mavenpro);
                    font-size: 14.5px;
                    width: 125px;
                    height: 45px;
                    background-color: #121212;
                    color: white;
                }
            }
        }
        .downloadReportButton{
            margin-left: 25px;
            .downloadReportBtn{
                border: 1px solid black;
                // padding: 12px;
                font-size: 15px;
                color: black;
                cursor: pointer;
                border-radius: 25px;
                width: 175px;
                height: 35px;
                text-align: center;
                padding-top: 5px;
            }
        }
        .reportName{
            width: 100%;
            margin-top: auto;
            font-size: 18px;
            margin-bottom: auto;
            font-weight: 400;
            text-align: center;
        }
    }

    .auditDataWrapper{
        margin-top: 75px;
        .subReportWrapper{
            margin-left: 25px;
            background-color: white;
            padding: 15px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            border-radius: 15px;
            margin-bottom: 25px;
            .subReportName{
                font-size: 22px;
                font-weight: 500;
                margin-bottom: 15px;
            }
            .subReportDataWrapper{
                display: flex;
                flex-direction: row;
                .subReportHeaders{
                    width: 30%;
                    font-size: 15px;
                    .colHeader{
                        height: 25px;
                        margin-bottom: 12px;
                        font-weight: 500;
                    }
                    .colData{
                        height: 25px;
                        margin-bottom: 7px;
                    }
                }
                .subReportData{
                    width: 70%;
                    font-size: 15px;
                    .colHeader{
                        height: 25px;
                        font-weight: 500;
                        margin-bottom: 12px;
                    }
                    .colData{
                        height: 25px;
                        margin-bottom: 7px;
                        font-weight: regular;
                    }
                }
            }
        }
    }
    .separator{
        margin: 15px 15px 15px 0px;
        border-bottom: 0.5px rgb(158, 130, 130) solid;
    }
}

// date filter
.displayCalendarBtn{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: fit-content;
    padding: 5px;
    border: 1px solid #DDDDDD;
    background-color: transparent;
}