.houseKeepingCharterTable2 {
  font-size: 15px;
  font-family: var(--mavenpro);
  font-size: 25px;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .nameHeaderWrapper2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    .headerOptions {
      cursor: pointer;
      align-self: left;
      display: flex;
      flex-direction: row;
      margin-left: 10px;
      margin-top: auto;
      margin-bottom: auto;
      .paginationWrapper {
        margin-left: 15px;
        .paginationSelection {
          width: 75px;
          height: 35px;
          color: black;
          // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          background-color: white;
          border: none;
          border-radius: 5px;
          padding: 3px;
          font-family: var(--mavenpro);
          border: 1px solid #dddddd;
          font-size: 15px;
        }
        .paginationSelection:focus {
          outline: none;
        }
      }
      .datePicker {
        position: relative;
        height: 35px;
        margin-left: 15px;
        margin-top: 5px;
      }
      .roomTypeFilter {
        margin-left: 15px;
        position: relative;
        margin-top: 5px;
      }
      .columnVisibilityWrapper {
        margin-left: 15px;
        text-align: center;
        .columnSelector {
          width: 75px;
          height: 35px;
          color: black;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          background-color: white;
          border: none;
          border-radius: 5px;
          padding: 3px;
          font-family: var(--mavenpro);
        }
      }
      .searchFunctionality {
        .searchInp {
          width: 200px;
          height: 35px;
          color: black;
          // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          background-color: white;
          padding: 15px;
          border: 1px solid #dddddd;
          border-radius: 5px;
          font-family: var(--mavenpro);
          font-size: 15px;
        }
        .searchInp:focus {
          outline: none;
        }
      }
      .displayCalendarBtn {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border: 1px solid #dddddd;
        height: 45px;
        border-radius: 5px;
        max-width: 250px;
        margin-left: 15px;
        background-color: transparent;
        .calIcon {
          margin-top: auto;
          margin-bottom: auto;
        }
        .calDate {
          margin-top: auto;
          margin-bottom: auto;
          margin-left: 2.5px;
        }
      }
      .calendar {
        margin-left: 15px;
        height: 45px;
      }
      .dateFilterType {
        margin-left: 15px;
        .dateFilterWrapper {
          align-items: center;
          display: flex;
          flex-direction: row;
          background-color: white;
          color: black;
          // box-shadow: #0000003d 0px 3px 8px;
          max-width: 250px;
          height: 45px;
          border-radius: 5px;
          padding-left: 5px;
          font-size: 15px;
          border: 1px solid #dddddd;
        }
        .dateFilterWrapper:focus {
          outline: none;
        }
      }
      .exportWrapper {
        margin-left: 15px;
        .exportOptionsWrapper {
          height: 35px;
          width: 150px;
          border: 1px solid #dddddd;
          border-radius: 5px;
          // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          background-color: white;
          padding-left: 4.5px;
          color: black;
          font-family: var(--mavenpro);
          font-size: 15px;
        }
      }
    }
    .reportName {
      text-align: right;
      font-size: 18px;
      font-weight: 400;
      margin-top: auto;
      margin-right: 35px;
    }
  }
}
.agTable2 {
    margin-top: 35px;
    margin-left: 10px;
    margin-right: 25px;
    margin-bottom: 0px;
    .ag-theme-alpine2 {
      // padding: 25px;
      margin-right: 25px;
      // margin-right:35px;
      // height: 725px;
      height: 88%;
      text-align: left;
      font-family: var(--mavenpro);
      width: 100%;
      position: absolute;
      border-radius: 5px;
      // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      background-color: transparent;
      @include ag-theme-alpine(
        (
          font-family: var(--mavenpro),
          header-height: 20px,
          border-radius: 8px,
          border-color: white,
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px,
          foreground-color: black,
          // height: 100%,
            position: absolute,
        )
      );
    }
  }

  .cell-fail {
    background-color: #f29191;
    color: black;
  }
  