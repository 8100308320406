.modal {
    height: 100%;
    /* flex-direction: row-reverse; */
    /* animation: openModal 0.2s ease-in; */
  }
  .myModalBody65333 {
    /* margin: 0 0 0 auto !important; */
    height: 100vh !important;
    max-width: 70vw !important;
  }
  .myModalContent323233 {
    border-radius: 0;
    height: 90vh;
    overflow-y: auto;
  }
  
