.clickAwayListenerWrapper {
  font-family: var(--mavenpro);
  position: relative;
}

.clickAwayContentWrapper {
  display: flex;
  flex-direction: column;
}

.clickAwayBtn {
  padding: 7.5px;
  background-color: black;
  color: white;
  font-family: var(--mavenpro);
  font-size: 16.5px;
  border: none;
  border-radius: 5px;
}

.clickAwayListenerContent {
  position: absolute;
  z-index: 1;
  width: max-content;
  // padding-right: 25px;
  padding: 15px 20px 25px 25px;
  font-family: var(--mavenpro);
  font-size: 15px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  margin-top: 45px;
  background-color: white;
  border: none;
  border-radius: 5px;
  // right: 22.5px;
}
