.modal {
    height: 100% !important;
    flex-direction: row-reverse;
    animation: openModal 0.2s ease-in;
  }
  .myModalBodyeee {
    margin: 0 0 0 auto !important;
    max-width: 40vw !important;
    height: 100vh !important;
    margin-left: auto !important;
  }
  .myModalContent {
    border-radius: 0;
    height: 100vh;
    overflow-y: auto;
  }
  
  @keyframes openModal {
    0% {
      transform: translate(60vw, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  
  .customModalsssaaskg {
    background: #b2dbbf;
    max-width: 500px;
    width: 100%;
  }
  