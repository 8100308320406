.ishaEnquiryValPage {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  .ishaInfoWrapper {
    position: relative;
    margin: 3%;
    .validationMsgWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      .validationMsg {
        position: relative;
        word-wrap: break-word;
        font-size: 22px;
      }
    }
    .validationMsgWrapper strong {
      font-size: 25px;
    }
    .addInfoWrapper {
      position: relative;
      margin-top: 25px;
      display: flex;
      flex-direction: row;
      .addInfo {
        position: relative;
        width: 33%;
        display: flex;
        flex-direction: row;
        padding-right: 10px;
        .addInfo h4 {
          position: relative;
          width: 27.5%;
          word-wrap: break-word;
          font-size: 13.5px;
          margin-left: 2.5px;
        }
        .infoField {
          position: relative;
          width: 67.5%;
          margin-right: 10px;
        }
      }
    }
    .uploadWrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      .uploadItem {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        width: 33%;
        padding: 10px;
      }
    }
    .saveBtnWrapper {
      display: flex;
      position: relative;
      width: 100%;
      justify-content: flex-end;
      margin-top: 10px;
      margin-bottom: 10px;
      .saveBtn {
        width: "fit-content";
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .ishaEnquiryValPage {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;
    background-color: white;
    padding: 10px;
    .ishaInfoWrapper {
      position: relative;
      .validationMsgWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        .validationMsgWrapper strong {
          font-size: 17.5px;
        }
        .validationMsg {
          position: relative;
          width: 100%;
          word-wrap: break-word;
          font-size: 15px;
          margin-top: 5px;
        }
      }
      .addInfoWrapper {
        position: relative;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        .addInfo {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 12.5px;
          .addInfo h4 {
            position: relative;
            width: 100%;
            word-wrap: break-word;
            font-size: 13.5px;
          }
          .infoField {
            position: relative;
            width: 100%;
            margin-top: 5px;
          }
        }
      }
      .uploadWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        .uploadItem {
          display: flex;
          flex-direction: column;
          margin-top: 5px;
          width: 100%;
          padding: 10px;
        }
      }
      .saveBtnWrapper {
        display: flex;
        position: relative;
        width: 100%;
        margin-top: 10px;
        justify-content: center;
        .saveBtn {
          position: relative;
          width: 95%;
        }
      }
    }
  }
  .ishaEnquiryValPage::-webkit-scrollbar {
    display: none;
  }
}
