.roomWrapperLoader {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .roomInfoLoader {
    width: 125px;
    height: 50px;
    animation-duration: 1.5s;
    margin-right: 5px;
    margin-bottom: 5px;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    background: #f6f7f81a;
    background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
    border-radius: 5px;
  }
}
