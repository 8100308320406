.bookingInformationWrapper {
  font-family: var(--mavenpro);
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  // align-items: center;
  margin-top: 25px;
  // justify-content: center;
  .info {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 14.5px;
    margin-bottom: 12.5px;
    // flex-wrap: wrap;
    .infoHeader {
      color: black;
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      font-weight: bold;
    }
    .infoVal {
      color: black;

      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-right: 10px;
    }
  }
}
.cancelBookingBtn {
  position: relative;
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  .cancelBtn {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    font-size: 15px;
    font-family: var(--mavenpro);
    font-weight: 500;
    align-self: flex-end;
  }
}
