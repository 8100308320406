.cardscsss {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 5px;
  flex-wrap: wrap;
}

.cardss{
  display: flex;
  position: relative;
  width: 100%;
  padding: 5px;
  flex-wrap: wrap;
  margin-right: 5px;
}

.cardess {
  background: #fff;
  width: 175px;
  border-radius: 0.6em;
  /* margin: 1em; */
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
  margin-right:10px;
  margin-bottom: 10px;
}

.cardess:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
    0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
    0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
    background-image: var(--backgroundImg);
}

.cardess h2 {
  color: #222;
  margin-top: -0.2em;
  /* line-height: 1.4; */
  position: relative;
  font-size: 13.5px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  transition: all ease-in 100ms;
}

/* .cardess:hover h2,p{
    color:white;
}

.cardess p {
  color: #777;
  font-size: 12px;
} */

.card-bodyss {
  padding: 5px;
  width:100%;
  max-width: fit-content;
  overflow: hidden;
  overflow-y: scroll;
  word-wrap: break-word;
}
