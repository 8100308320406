.cashPaymentModalWrapper{
    font-family: var(--mavenpro);
    background-color: transparent;
    margin-top: 5px;
    justify-content: center;
    .CPHeader{
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 8px;
    }
    .CPEdits{
        padding-right: 25px;
        padding-left: 25px;
        .CPEdit{
            margin-top: 30px;
        }
    }
}

// call to action buttons
.CTABtns{
    margin-top: 65px;
    display: inline-block;
    position: relative;
    width: 100%;
    .cancel{
        float: left;
        margin-left: 15px;
        font-family: var(--mavenpro);
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        border: none;
        background-color: transparent;
    }
    .submit{
        float: right;
        margin-right: 15px;
        border: none;
        background-color: #00C690;
        font-family: var(--mavenpro);
        font-size: 16px;
        border-radius: 8px;
        padding: 5px;
        color: white;
        width: 75px;
    }
}