.posViewOrdersWrapper{
    display: flex;
    flex-direction: column;
    .filterWrapper{
        display: flex;
        flex-direction: row;
        .searchWrapper{
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 10px;
        }
    }
    .calendarWrapper{
        margin-top: auto;
        margin-bottom: auto;

        .calIcon{
            margin-top: auto;
            margin-bottom: auto;
        }
        .calDate{
            margin-top: auto;
            margin-bottom: auto;
            font-family: var(--mavenpro);
            font-size: 13.5px;
            margin-left: 5px;
        }
        .displayCalendarBtn{
            margin-top: auto;
            margin-bottom: auto;
            font-family: var(--mavenpro);
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            background-color: white;
            color: black;
            padding:12.25px;
            width: 250px;
            margin-left: 15px;
            border: 1px solid #DDDDDD;
            display: flex;
            flex-direction: row;
        }
    }
    .toggleView{
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 25px;
    }
    .posViewOrders{
        margin-top: 25px;
        display: flex;
        flex-direction: column;
    }

}


// pos view order card wrapper design 
.posViewOrderCardWrapper{
    display: flex;
    flex-direction: column;
    font-family: var(--mavenpro);
    font-size: 15px;
    color: black;
    .informationWrapper{
        display: flex;
        flex-direction: column;
        .orderInformation{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            .orderId{
                margin-top: auto;
                margin-bottom: auto;
                font-weight: 500;
                font-size: 15px;
            }
            .orderStatus{
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 10px;
                font-size: 12.5px;
                border-radius: 25px;
                color: white;
                background-color: green;
                width: fit-content;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        .orderAmount{
            font-size: 13.5px;
            margin-top: 5px;
        }
        .supportInfo{
            display: flex;
            margin-top: 5px;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            .tableInfo{
                margin-top: auto;
                margin-bottom: auto;
            }
            .staffInfo{
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        .customerInfo{
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            .custEmail{
                margin-top: auto;
                margin-bottom: auto;
            }
            .custContact{
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        .custRoomInfo{
            margin-top: 5px;
        }
        .orderDescription{
            margin-top: 8px;
        }
    }
}

.ctaWrapper{
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    .posCta{
        border-radius: 5px;
        font-family: var(--mavenpro);
        font-size: 15px;
        padding: 5px;
        background-image: var(--backgroundImg);
        color: white;
        border: none;
        margin-left: 7.5px;
    }
}


// view order confirmation modal
.viewOrderConfirmationModal{
    padding: 12px;
    border-radius: 5px;
    font-family: var(--mavenpro);
    font-size: 15px;
    width: 350px;
    text-align: center;
    .confirmationTxt{
        margin-top: 25px;
    }
    .btns{
        display: flex;;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 15px;

        .cancel{
            border: none;
            background-color: transparent;
            font-family: var(--mavenpro);
            font-size: 15px;
            font-weight: 500;
            text-decoration: underline;
            margin-top: auto;
            margin-bottom: auto;
        }
        .submit{
            border: none;
            font-weight: 500;
            background-image: var(--backgroundImg);
            font-size: 15px;
            color: white;
            padding: 5px;
            border-radius: 5px;
            margin-top: auto;
            margin-bottom: auto;
            width: 110px;
            height: 45px;
        }
    }
}

// settle dues modal
settleDuesModal{
    width: fit-content;
    border-radius: 5px;
    font-family: var(--mavenpro);
    font-size: 15px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    .settleDuesModalWrapper{
        .settleDuesHeader{
            border-bottom: 1px solid black;
            font-weight: 500;
        }
        .settleDuesContent::-webkit-scrollbar{
            display: none;
        }
        .settleDuesBtnWrapper{
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .cancelBtn{
                border: none;
                font-family: var(--mavenpro);
                font-size: 15px;
                font-weight: 500;
                text-decoration: underline;
                background-color: transparent;
            }
            .submitBtn{
                border: none;
                border-radius: 5px;
                width: 115px;
                height: 40px;
                font-family: var(--mavenpro);
                font-size: 15px;
                font-weight: 500;
                text-decoration: underline;
                background-image: var(--backgroundImg);
            }
        }
    }
}
.settleDuesModal::-webkit-scrollbar{
    display: none;
}