.hkCharterTableWrapper{
    font-family: var(--mavenpro);
    .toggleBtnGrp{
        display: flex;
        flex-direction: row;
        width: 100%;
        .groupRoomIds{
            border: none;
            background-color:rgb(167, 162, 162);
            font-family: var(--mavenpro);
            font-size: 15px;
            margin-top: 25px;
            margin-right: 25px;
            border-radius: 8px;
            padding: 12px;
            .roomDetails{
                display: flex;
                flex-direction: row;
                .roomNum{
                    border-radius: 50%;
                    height: 25px;
                    width: 25px;
                    background-color: white;
                    color: black;
                    margin-top: auto;
                    margin-bottom: auto;
                }
                .roomStatus{
                    margin-top: auto;
                    margin-bottom: auto;
                    color: white;
                    font-size: 12.5px;
                    margin-left: 25px;
                }
            }
        }
    }
    .groupRoomIds::selection{
        background-color: royalblue;
        color: white;
    }
    .filtersWrapper{
        display: flex;
        flex-direction: row;
        .hkSearchBar{
            // border-radius: 25px;
            width: 225px;
            font-family: var(--mavenpro);
            border: 0.5px solid rgb(167, 162, 162);
            margin-bottom: 25px;
        }
        .hkSearchbar::placeholder{
            color: #121212;
        }
        .staffFiltersListenerWrapper{
            margin-left: 25px;
            display: flex;
            flex-direction: column;
            position: relative;
            .staffFilterButton{
                border-radius: 12px;
                padding: 12px;
                width: fit-content;
                font-family: var(--mavenpro);
                font-size: 16px;
            }
            .staffFilters{
                display: flex;
                flex-direction: column;
                border-radius: 12px;
                position: absolute;
                background-color: white;
                z-index: 100;
                padding: 15px;
                width: 300px;
                .staffFilter{
                    display: flex;
                    flex-direction: row;
                    .filterCheckbox{
                        height: 15px;
                        width: 15px;
                    }
                    .filterText{
                        margin-left: 12px;
                        font-weight: 500;
                        font-size: 15px;
                        font-family: var(--mavenpro);
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                }
            }
        }
    }
}