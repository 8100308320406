// Inventory and stock management
// Raw material supply
.addUpdateModal {
  position: relative;
  width: 750px;
  height: 100%;
  .headerWrapper {
    position: relative;
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .heading {
      // padding: 10px;
      background-color: transparent;
      .heading strong {
        font-size: 20px;
      }
    }
    .close {
      cursor: pointer;
    }
  }
  .fields {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    margin-top: 25px;
    margin-left: 15px;
    .field {
      position: relative;
      margin-right: 10px;
      margin-bottom: 15px;
      margin-top: 1rem;
    }
  }
  .ctas {
    // position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 25px;
    display: flex;
    justify-content: end;
    .cancelBtn {
      text-decoration: none;
      color: black;
      text-transform: none;
      margin-top: auto;
      margin-bottom: auto;
    }
    .successBtn {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.addUpdateModalWastage {
  position: relative;
  width: 1400px;
  height: 100%;
  .headerWrapper {
    position: relative;
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .heading {
      // padding: 10px;
      background-color: transparent;
      .heading strong {
        font-size: 20px;
      }
    }
    .close {
      cursor: pointer;
    }
  }
  .fields {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    margin-top: 25px;
    margin-left: 15px;
    .field {
      position: relative;
      margin-right: 10px;
      margin-bottom: 15px;
      margin-top: 1rem;
    }
  }
  .ctas {
    // position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 25px;
    display: flex;
    justify-content: end;
    .cancelBtn {
      text-decoration: none;
      color: black;
      text-transform: none;
      margin-top: auto;
      margin-bottom: auto;
    }
    .successBtn {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.addUpdateModalPuchaseOrder {
  position: relative;
  width: 100rem;
  height: 100%;
  .headerWrapper {
    position: relative;
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: end;
    .heading {
      // padding: 10px;
      background-color: transparent;
      .heading strong {
        font-size: 20px;
      }
    }
    .close {
      cursor: pointer;
    }
  }
  .fields {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    margin-top: 25px;
    margin-left: 15px;
    .field {
      position: relative;
      margin-right: 10px;
      margin-bottom: 15px;
      margin-top: auto;
      margin-top: 1rem;
    }
  }
  .ctas {
    // position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    // .cancelBtn {
    //   text-decoration: none;
    //   color: black;
    //   text-transform: none;
    //   margin-top: auto;
    //   margin-bottom: auto;
    // }
    // .successBtn {
    //   margin-top: auto;
    //   margin-bottom: auto;
    // }
  }
}

// icons
.editIcon {
  cursor: pointer;
}
.deleteIcon {
  cursor: pointer;
}

// Delete modal
.deleteModalWrapper {
  .modalHeaderWrapper {
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .confirm {
    position: relative;
    margin-top: 10px;
    padding-left: 10px;
    word-wrap: break-word;
  }
  .btns {
    position: relative;
    margin-top: 25px;
    padding: 10px;
    display: flex;
    justify-content: end;
  }
}
