.mockLoaderTable {
  width: 100%;
  height: auto;
  margin-top: 2.5rem;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 16px 6px #0000001a;
  .box {
    border-radius: 0.5rem;
    animation-duration: 1.5s;
    flex-grow: 1;
    margin: 0.25rem;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: ease-in-out;
    background: #f6f7f81a;
    background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
    background-size: 1000px 104px;
    position: relative;
    overflow: hidden;
  }
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
}

// mock invoice wrapper table

.mockInvoiceTable {
  width: 100%;
  height: auto;
  margin-top: 2.5rem;
  border-radius: 5px;
  background-color: white;
  // box-shadow: 0px 0px 16px 6px #0000001a;
  border: 1px solid #dddddd;
}

.box {
  border-radius: 0.5rem;
  animation-duration: 1.5s;
  flex-grow: 1;
  margin: 0.25rem;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: ease-in-out;
  background: #f6f7f81a;
  background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
  background-size: 1000px 104px;
  position: relative;
  overflow: hidden;
}

// flash manager mock table
.flashManagerLoader {
  border-radius: 0.5rem;
  animation-duration: 1.5s;
  flex-grow: 1;
  // margin: 0.25rem;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: ease-in-out;
  background: #f6f7f81a;
  background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
  background-size: 1000px 104px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 175px;
  border-radius: 5px;
  background-color: white;
}

.paynowloader {
  border-radius: 0.5rem;
  animation-duration: 1.5s;
  flex-grow: 1;
  // margin: 0.25rem;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: ease-in-out;
  background: #f6f7f81a;
  background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
  background-size: 1000px 104px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 400px;
  border-radius: 5px;
  background-color: white;
}

// access control mock table
.accessControlLoader {
  border-radius: 0.5rem;
  animation-duration: 1.5s;
  flex-grow: 1;
  // margin: 0.25rem;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: ease-in-out;
  background: #f6f7f81a;
  background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
  background-size: 1000px 104px;
  position: relative;
  overflow: hidden;
  width: 86rem;
  height: 175px;
  border-radius: 5px;
  background-color: white;
}

// Place order table view
.placeOrderTables {
  .tableBoxes {
    height: 100px;
    width: 100px;
    border-radius: 0.5rem;
    animation-duration: 1.5s;
    // margin: 0.25rem;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: ease-in-out;
    background: #f6f7f81a;
    background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
    background-size: 1000px 104px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    background-color: white;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

// Card loaders
.cardsLoader {
  .cardLoader {
    height: 100px;
    width: 100%;
    border-radius: 0.5rem;
    animation-duration: 1.5s;
    // margin: 0.25rem;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: ease-in-out;
    background: #f6f7f81a;
    background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
    background-size: 1000px 104px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    background-color: white;
    margin-bottom: 8px;
  }
  .shopCardLoader {
    height: 200px;
    width: 100%;
    border-radius: 0.5rem;
    animation-duration: 1.5s;
    // margin: 0.25rem;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: ease-in-out;
    background: #f6f7f81a;
    background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
    background-size: 1000px 104px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    background-color: white;
    margin-bottom: 8px;
  }
  .imgLoader {
    height: 150px;
    width: 275px;
    border-radius: 0.5rem;
    animation-duration: 1.5s;
    // margin: 0.25rem;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: ease-in-out;
    background: #f6f7f81a;
    background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
    background-size: 1000px 104px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    background-color: white;
    margin-left: 10px;
  }
}

.menuItemLoaders {
  .menuItemLoader {
    height: 75px;
    width: 150px;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: ease-in-out;
    background: #f6f7f81a;
    background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
    background-size: 1000px 104px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    background-color: white;
    margin-bottom: 8px;
    margin-right: 7.5px;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.itemViewLoaders {
  position: relative;
  width: 100%;
  display: flex;
  .itemViewLoader {
    position: relative;
    display: flex;
    justify-content: center;
    height: 25px;
    width: 92%;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: ease-in-out;
    background: #f6f7f81a;
    background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
    background-size: 1000px 104px;
    margin-bottom: 8px;
    border-radius: 10px;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.menuLoaders {
  position: relative;
  margin-top: 15px;
  margin-bottom: 175px;
  .menuLoader {
    position: relative;
    display: flex;
    width: 92.5%;
    height: 30px;
    border-radius: 7.5px;
    animation-duration: 1.5s;
    animation-name: placeHolderShimmer;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    background: #f6f7f81a;
    background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
    background-size: 1000px 104px;
    margin-bottom: 10px;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
