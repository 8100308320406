.reportsTypeCards {
  position: relative;
  width: 24em;
  border: none;
  border-radius: 7.5px;
  display: flex;
  flex-direction: column;
  transition: all ease 200ms;
  overflow: hidden;
  overflow-y: scroll;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.reportsTypeCards h2 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.3em;
  font-weight: 500;
  font-family: var(--mavenpro);
  transition: all ease-in 100ms;
}

.reportsTypeCards:hover {
  background: var(--backgroundImg);
  background-image: var(--backgroundImg);
  color: white;
  transform: scale(1.03);
}

.reportsTypeCards:hover h2 {
  color: yellow;
}

.reportsTypeCards:hover button {
  background-image: none;
  background-color: white;
  color: black;
  transition: scale(1.03);
}

.reportsTypeCards p {
  color: #777;
}

.reportsTypeCards:hover p {
  color: white;
}

.reportsTypeBody {
  padding: 1.2em;
  height: 12rem;
}

.reportTypeViewBtn {
  position: relative;
  width: auto;
  margin-left: auto;
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: block;
  background-image: var(--backgroundImg);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: none;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
}

.reportTypeViewBtn:hover {
  background-image: none;
  background-color: white;
  color: black;
  transition: scale(1.03);
}
