.modal {
    height: 100%;
    flex-direction: row-reverse;
    animation: openModal 0.2s ease-in;
  }
  .myModalBody {
    margin: 0 0 0 auto;
    max-width: 47vw !important;
    height: 100vh;
  }
  .myModalContent {
    border-radius: 0;
    height: 100vh;
    overflow-y: auto;
  }

  .myModalBodyCTAImg {
    margin: 0 0 0 auto;
    max-width: 40vw !important;
    height: 100vh;
  }
  .myModalContentCTAImg {
    border-radius: 0;
    height: 60vh;
    overflow-y: auto;
  }

  /* .myModalBodyConfirmBooking {
    margin: 0 0 0 auto;
    max-width: 40vw !important;
    height: 30vh;
  } */
  
  
  @keyframes openModal {
    0% {
      transform: translate(60vw, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  