.foliosWrapper {
  position: relative;
  width: 97.5vw;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 5px;
  overflow: hidden;
  overflow-y: scroll;
  // margin-top: -25px;
  .foliosHeader {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .toggleAdvanced {
      margin-left: 10px;
    }
    .folioCTA {
      display: flex;
      flex-direction: row;
      // margin-right: 15px;
      .downloadFolio {
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
      }
      .closeFolio {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 17.5px;
        cursor: pointer;
      }
    }
  }
  .folioContentWrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    .invoice {
      position: relative;
      width: 75%;
      padding-right: 20px;
    }
    .invoice::-webkit-scrollbar {
      display: none;
    }
    .billBreakdown {
      position: absolute;
      width: 25%;
      margin-right: 25px;
      right: 0;
      bottom: 0;
      top: 0;
      margin-top: 75px;
    }
  }
}

// print invoice
.printableInvoice {
  display: flex;
  flex-direction: column;
  font-family: var(--mavenpro);
  font-size: 15px;
}

// folio log table
.folioLogModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: none;
  box-shadow: 24;
  font-family: var(--mavenpro);
  padding: 15px;
  .folioLogHeader {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    padding: 5px;
    padding-bottom: 10px;
    .folioLogHeading {
      font-family: var(--mavenpro);
      font-size: 15px;
      font-weight: 500;
    }
    .close {
      font-size: 25px;
      font-family: var(--mavenpro);
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.folioLogTable {
  margin-top: 15px;
  font-family: var(--mavenpro);
  font-size: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: fit-content;
  .filtersWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    .printFolioLogBtn {
      font-size: 15px;
      font-family: var(--mavenpro);
      font-weight: 500;
      border: none;
      width: 125px;
      color: white;
      background-color: black;
      border-radius: 5px;
      height: 40px;
    }
  }
}
