.staffAssignmentWrapper {
  font-family: var(--mavenpro);
  margin-top: 45px;
  .searchFiltersWrapper {
    display: flex;
    flex-direction: row;
    .searchBarWrapper {
      .searchBar {
        border: 1px solid black;
        border-radius: 25px;
        width: 225px;
        padding: 7.5px;
        font-family: var(--mavenpro);
        color: black;
        background-color: white;
        font-size: 14.5px;
      }
      .searchBar::placeholder {
        padding-left: 15px;
        font-size: 14.5px;
      }
    }
    .staffFiltersWrapper {
      margin-top: auto;
      margin-bottom: auto;
      font-family: var(--mavenpro);
      color: black;
      // margin-left: 25px;
      .staffFilters {
        font-family: var(--mavenpro);
        font-size: 14.5px;
        color: black;
        border-radius: 25px;
        padding: 7.5px;
        border: 0.5px solid rgb(167, 162, 162);
        background-color: transparent;
        width: 175px;
      }
      .staffFilters:focus {
        outline: none;
      }
    }
    .roomTypeFilters {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 10px;
      .roomFilters {
        font-family: var(--mavenpro);
        font-size: 14.5px;
        color: black;
        border-radius: 25px;
        padding: 7.5px;
        border: 0.5px solid rgb(167, 162, 162);
        background-color: transparent;
        width: 175px;
      }
      .roomFilters:focus {
        outline: none;
      }
    }
    .assignUpdateButton {
      margin-left: 10px;
      margin-top: auto;
      margin-bottom: auto;
      .assignUpdateBtn {
        font-family: var(--mavenpro);
        font-size: 14.5px;
        border-radius: 5px;
        width: 175px;
        height: 45px;
        color: white;
        // border: 2px solid linear-gradient(-150deg, #1ab394, #1a91ae);
        background-color: #121212;
        // background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
        font-weight: 500;
        border: none;
      }
    }
  }
  .staffTableWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 25px;
    .roomTable {
      background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
      display: flex;
      flex-direction: row;
      padding: 12px;
      border: none;
      border-radius: 8px;
      font-family: var(--mavenpro);
      margin-right: 25px;
      margin-top: 25px;
      .roomId {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 16.5px;
        color: white;
      }
      .assignedInputField {
        border: none;
        .assignedInpField {
          margin-top: auto;
          margin-bottom: auto;
          margin-left: 25px;
          border: none;
          border-radius: 5px;
          height: 22px;
          width: 22px;
          background-color: transparent;
        }
        // background-color: white;
      }
    }
  }
}
