.shopConfigurationAlternateWrapper{
    font-family: var(--mavenpro);
    display: flex;
    flex-direction: row;
    .shopConfigurationWrapper{
        // margin: auto;
        margin-top: 25px;
    }
    .separator{
        border-left: 1px solid black;
        height: 1000px;
    }
    .startOrderWrapper{
        margin-top: 25px;
        margin-left: 25px;
    }
}