.propDetailsWrapper {
  font-family: var(--mavenpro);
  position: relative;
  padding-right: 1%;
  // padding-left: 5%;
  .details {
    margin-top: 35px;
    position: relative;
    display: flex;
    flex-direction: column;
    .mock-loader {
      width: 100%;
      height: auto;
      border-radius: 10px;
      background-color: #f6f7f8;
      max-width: initial;
    }

    .mock-loader .box {
      border-radius: 0.5rem;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: #f6f7f81a;
      background: linear-gradient(
        to right,
        #eeeeee 4%,
        #dddddd 8%,
        #eeeeee 16%
      );
      background-size: 1000px 104px;
      position: relative;
      overflow: hidden;
    }
    .galleryWrapper {
      display: grid;
      grid-template-columns: 50% 50%;
      height: 54vh;
      justify-content: center;
      position: relative;
      .editPhotos {
        position: absolute;
        display: flex;
        z-index: 10;
        left: 0;
        margin-top: 15px;
        margin-left: 15px;
        border: none;
        background-color: transparent;
        background-color: #dddddd;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 50%;
        padding-top: 5px;
      }
      .coverImage {
        position: relative;
        overflow: hidden;
        height: 54vh;
        width: 100%;
        margin-top: auto;
        border-radius: 15px 0px 0px 15px;
        margin-bottom: auto;
        // background-image: var(--backgroundImg);
        background-image: "white";
        text-align: center;
        .coverImg {
          height: 54vh;
          width: 100%;
          cursor: pointer;
        }
        .emptyPlaceholder {
          margin: auto;
          align-self: center;
          height: 54vh;
          object-fit: cover;
          width: 100%;
          // margin-top: 75px;
        }
      }
      .otherImages {
        margin-top: auto;
        margin-bottom: auto;
        // margin-left: 25px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;
        width: auto;
        .otherImgWrapper {
          width: 50%;
          height: 27vh;
          overflow: hidden;
          // margin-bottom: 15px;
          // background-image: var(--backgroundImg);
          background-image: "white";
          text-align: center;
          .otherImg {
            position: relative;
            height: 27vh;
            width: auto;
            // margin-bottom: 15px;
            cursor: pointer;
          }
          .emptyPlaceholder {
            margin: auto;
            align-self: center;
            object-fit: cover;
            height: 27vh;
            width: 100%;
            // margin-top: 15px;
          }
        }
        #sideImg1 {
          padding: 0px 5px 5px;
        }
        #sideImg2 {
          padding: 0px 0px 5px 5px;
          border-top-right-radius: 15px;
        }
        #sideImg3 {
          padding: 5px 0px 0px 5px;
        }
        #sideImg4 {
          padding: 5px 0px 0px 5px;
          border-bottom-right-radius: 15px;
        }
      }
    }
    .hotelDetailsWrapper {
      margin-top: 45px;
      display: inline-block;
      width: 100%;
      .basicDetails {
        float: left;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        color: #7c7c7c;
        .hotelName {
          font-size: 35px;
          font-weight: 500;
          color: black;
        }
        .hotelWeb {
          margin-top: 8px;
        }
        .address {
          margin-top: 8px;
        }
        .hotelContactDetails {
          display: flex;
          flex-direction: row;
          margin-top: 8px;
          .address {
            margin-top: auto;
            margin-bottom: auto;
          }
          .sep {
            height: 15px;
            margin-left: 8px;
            border-left: 1px solid #7c7c7c;
            margin-top: auto;
            margin-bottom: auto;
          }
          .phone {
            margin-top: auto;
            margin-bottom: auto;
            // margin-left: 8px;
          }
        }
        .editHotelDetails {
          margin-top: 0px;
          font-weight: 500;
          font-size: 16px;
          text-decoration: underline;
          color: black;
          cursor: pointer;
        }
      }
      .ratingAmenitiesWrapper {
        float: right;
        display: flex;
        flex-direction: column;
        .ratingsWrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .rating {
            border: none;
            border-radius: 8px;
            padding: 8px;
            color: white;
            display: flex;
            flex-direction: row;
            // background-color: #00C690;
            background-image: var(--backgroundImg);
            .fig {
              font-size: 15px;
              margin-top: auto;
              font-weight: 500;
              margin-bottom: auto;
            }
            .starIcon {
              margin-top: -1.25px;
              margin-bottom: auto;
              margin-left: 8px;
            }
          }
        }
        .ratingType {
          font-size: 12.5px;
          // margin-top: auto;
          margin-left: 15px;
        }
        .editPropRating {
          // font-size: 12.5px;
          // margin-top: auto;
          margin-left: 15px;
          .propEditIcon {
            border: none;
            background-color: transparent;
          }
          .editWrapper {
            font-family: var(--mavenpro);
            align-items: center;
            position: relative;
            width: 100%;
            .heading {
              font-weight: 500;
              font-size: 15px;
            }
            .inputEditableRating {
              font-family: var(--mavenpro);
              font-size: 14px;
              border: none;
              padding: 5px;
              margin-top: 15px;
              border-bottom: 1px solid #dddddd;
              color: grey;
              font-weight: 500;
            }
            .inputEditableRating:focus {
              outline: none;
            }
            .editPropBtns {
              margin-top: 20px;
              position: relative;
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .cancelBtn {
                border: none;
                font-family: var(--mavenpro);
                font-size: 14px;
                font-weight: 500;
                text-decoration: underline;
                color: black;
                background-color: transparent;
              }
              .saveBtn {
                background-image: var(--backgroundImg);
                color: white;
                font-weight: 500;
                width: 100px;
                height: 40px;
                padding: 7px;
                border-radius: 5px;
                border: none;
                justify-content: center;
              }
            }
          }
        }
        .horSep {
          width: 300px;
          border-top: 1px solid #dddddd;
          margin-top: 25px;
          margin-bottom: 25px;
          cursor: pointer;
        }
        .amenitiesHeading {
          margin-top: 8px;
          font-size: 20px;
          font-weight: 500;
        }
        .subHeading {
          margin-top: 8px;
          font-size: 12px;
          width: 275px;
        }
        .editAmenities {
          // margin-top: 25px;
          font-weight: 500;
          font-size: 16px;
          padding-right: 0.7rem;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

// amenities modal
.amenitiesModal {
  border-radius: 10px;
  font-family: var(--mavenpro);
  padding: 10px;
  width: 630px;
  height: 90%;
  overflow-y: scroll;
  position: relative;
  .amenitiesModalHeader {
    margin-top: 15px;
    font-weight: 500;
    position: relative;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 15px;
    text-align: center;
    font-size: 15px;
  }
  .amenitiesWrapper {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    // position: relative;
    // width: 100%;
    .amenitiesList {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
      padding-bottom: 15px;
      border-bottom: 1px solid #dddddd;
      .basicInfo {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        // display: grid;
        // grid-template-columns: 90% 20%;
        position: relative;
        width: 100%;
        .iconWrapper {
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          margin-top: auto;
          margin-bottom: auto;
          // width: 85%;
          .disabledIcon {
            background-color: rgb(206, 206, 206);
            height: 50px;
            width: 50px;
            border-radius: 50%;
            margin-top: auto;
            margin-bottom: auto;
            text-align: center;
            padding-top: 15px;
          }
          .enabledIcon {
            background-image: var(--backgroundImg);
            height: 50px;
            width: 50px;
            border-radius: 50%;
            margin-top: auto;
            margin-bottom: auto;
            text-align: center;
            padding-top: 15px;
          }
          .amenitiesName {
            font-size: 16.5px;
            font-weight: 500;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 15px;
          }
        }
      }
      .priceInfo {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        // display: grid;
        // grid-template-columns: 90% 20%;
        position: relative;
        width: 100%;
        margin-top: 25px;
      }
    }
  }
}

.btnWrapper {
  position: relative;
  height: 10%;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 25px;
  .cancelBtn {
    border: none;
    font-family: var(--mavenpro);
    font-size: 15px;
    font-weight: bold;
    text-decoration: underline;
    background-color: transparent;
    margin-top: auto;
    margin-bottom: auto;
  }
  .saveBtn {
    border: none;
    border-radius: 10px;
    width: 120px;
    height: 45px;
    margin-top: auto;
    margin-bottom: auto;
    background-image: var(--backgroundImg);
    font-family: var(--mavenpro);
    font-size: 15px;
    font-weight: bold;
    color: white;
  }
}

.addAmenityBtn {
  border: none;
  width: 90px;
  height: 40px;
  border-radius: 7px;
  padding: 5px;
  font-family: var(--mavenpro);
  font-size: 15px;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  background-color: transparent;
  margin-right: 15px;
  background-image: var(--backgroundImg);
}
.removeAmenityBtn {
  border: 1px solid #dddddd;
  width: 90px;
  height: 40px;
  border-radius: 7px;
  padding: 5px;
  font-family: var(--mavenpro);
  font-size: 15px;
  color: grey;
  margin-top: auto;
  margin-bottom: auto;
  background-color: transparent;
  margin-right: 15px;
}

.addPrice {
  width: 25%;
  // margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
  margin-top: -25px;
}

.priceToggleIcon {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 25px;
  width: 55%;
}

.amenitiesModal::-webkit-scrollbar {
  display: none;
}

// manage room types
.horzSeparator {
  margin-top: 25px;
  margin-bottom: 25px;
  padding-right: 1%;
  border-top: 1px solid #dddddd;
}

.manageRoomTypesWrapper {
  position: relative;
  width: 100%;
  font-family: var(--mavenpro);
  padding-right: 1%;
  // padding-left: 5%;
  margin-top: 35px;
  .headerWrapper {
    display: flex;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    .manageRoomTypeHeader {
      font-size: 25px;
      font-weight: 500;
    }
    .addRoomTypeBtn {
      border: none;
      font-family: var(--mavenpro);
      font-size: 15px;
      color: white;
      padding: 5px;
      border-radius: 10px;
      width: 120px;
      height: 45px;
      background-image: var(--backgroundImg);
    }
  }
  .roomTypes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 50px;
    // justify-content: space-between;
  }
}

// hotel image edit modal
.editImgModal {
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  max-height: 90%;
  font-family: var(--mavenpro);
  font-size: 15px;
  padding: 15px;
  .editHotelImgHeader {
    text-align: center;
    font-weight: 500;
  }
  .editRoomTypeHeader {
    text-align: center;
    font-weight: 500;
  }
  .horizontalSep {
    width: 100%;
    border-top: 1px solid black;
    margin-top: 10px;
  }
  .editCoverImg {
    margin-top: 10px;
    font-weight: 500;
    font-size: 15px;
  }
  .hotelImgs {
    margin-top: 25px;
    font-weight: 500;
    font-size: 15px;
  }
  .roomImgs {
    margin-top: 25px;
    font-weight: 500;
    font-size: 15px;
  }
  .btnWrappers {
    position: relative;
    width: 100%;
    border-top: 1px solid #dddddd;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .saveImg {
      border: none;
      width: 100px;
      height: 45px;
      font-family: var(--mavenpro);
      color: white;
      font-size: 15px;
      background-image: var(--backgroundImg);
      border-radius: 5px;
    }
    .cancel {
      border: none;
      font-family: var(--mavenpro);
      font-size: 15px;
      color: black;
      background-color: transparent;
    }
  }
}

.filepond--item {
  width: calc(25% - 0.5em);
  height: 125px;
}

.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button {
  border: none;
}
