.modal {
  height: 100%;
  flex-direction: row-reverse;
  animation: openModal 0.2s ease-in;
}
.myModalBody {
  margin: 0 0 0 auto;
  max-width: 40vw !important;
  height: 100vh;
}
.myModalContent {
  border-radius: 0;
  height: 100vh;
  overflow-y: auto;
}

.myModalBody678 {
  margin: 0 0 0 auto;
  max-width: 100vw !important;
  height: 100vh;
}
.myModalContent678 {
  border-radius: 0;
  height: 100vh;
  overflow-y: auto;
}

@keyframes openModal {
  0% {
    transform: translate(60vw, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.chipsContainer {
  /* Enable wrapping for the chips */
  flex-wrap: wrap;
  /* Adjust the width as needed */
  max-width: 90%;
}
