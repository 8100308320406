// add room type drawer
.addRoomTypeWrapper{
    position: relative;
    width: 100%;
    font-family: var(--mavewnpro);
    display: flex;
    flex-direction: column;
    .addRoomTypeHeader{
        // margin-top: 12px;
        font-weight: 500;
        font-size: 15px;
        text-align: center;
        position: relative;
        width: auto;
        // padding-bottom: 15px;
        // border-bottom: 1px solid #DDDDDD;
    }
    .toggleView{
        margin-left: 45px;
        margin-top: 25px;
        .comboControl{
            font-family: var(--mavenpro);
            font-size: 17px;
        }
    }
    .roomTypeInfoWrapper{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 45px;
        justify-content: space-between;
        margin-top: 25px;
        position: relative;
        .roomTypeInput{
            margin-top: 25px;
        }
        .comboWrapper{
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            // margin-left: 45px;
            // margin-right: 45px;
            .comboInputFields{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                position: relative;
                width: 560px;
                .roomTypeInput{
                    margin-top: auto;
                    margin-bottom: auto;
                }
                .roomTypeForm{
                    margin-top: 1.5px;
                    margin-left: 35px;
                    margin-bottom: auto;
                    position: relative;
                }
            }
            .btn-box{
                margin-top: 7px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-end;
                .removeCombo{
                    border: none;
                    background-color: transparent;
                }
                .addCombo{
                    border: none;
                    background-image: var(--backgroundImg);
                    font-family: var(--mavenpro);
                    font-size: 15px;
                    color: white;
                    padding: 5px;
                    border-radius: 5px;
                    margin-left: 7px;
                    margin-top: auto;
                    margin-bottom: auto;
                    width: 125px;
                    height: 45px;
                }
            }
        }
    }
    .btn-box{
        margin-top: 10px;
        padding-bottom:10px;
        border-bottom: 1px solid #dddddd;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .cancel{
            border: none;
            font-family: var(--mavenpro);
            font-size: 15px;
            text-decoration: underline;
            font-weight: 500;
            background-color: transparent;
            border-radius: 5px;
            width: 125px;
            height: 45px;
        }
        .submit{
            border: none;
            font-family: var(--mavenpro);
            font-size: 15px;
            background-image: var(--backgroundImg);
            border-radius: 5px;
            width: 100px;
            height: 30px;
            color: white;
            margin-right: 45px;
        }
    }
}