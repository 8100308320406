.modifyDatesWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: var(--mavenpro);
  margin-left: 1rem;
  //   margin-right: 1rem;
  margin-top: 2rem;
  .accordianWrapper {
    // .accordianSummary{
    //     background-color: transparent;
    // }
    .accordianHeading {
      font-family: var(--mavenpro);
      padding: 12px;
      font-size: 15px;
      font-weight: 500;
    }
  }
  .checkInHeader {
    font-family: var(--mavenpro);
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;
    margin-top: 15px;
  }
  .checkOutHeader {
    font-family: var(--mavenpro);
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;
    margin-top: 65px;
  }
  .modifyCheckInDates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
    .newCheckInCalendar {
    }
    .newCheckInSlot {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      .checkInSlot {
        display: flex;
        flex-direction: column;
      }
    }
    .pricePerNight {
      margin-top: 25px;
    }
    .totalBeforeTax {
      margin-top: 25px;
    }
    .totalTax {
      margin-top: 35px;
    }
    .totalWithTax {
      margin-top: 35px;
    }
    .modifyDateBtns {
      margin-top: 30px;
      position: relative;
      width: 100%;
      // width: 100%;
      .cancel {
        border: none;
        background-color: transparent;
        font-family: var(--mavenpro);
        font-size: 16px;
        text-decoration: underline;
        color: black;
        font-weight: 500;
      }
      .extendBooking {
        border: none;
        // background-color: #00C690;
        background-image: var(--backgroundImg);
        font-family: var(--mavenpro);
        font-size: 15px;
        padding: 12px;
        border-radius: 5px;
        color: white;
        position: relative;
        width: 100%;
        // margin-left: 35px;
      }
    }
  }
  .modifyCheckOutDates {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
    position: relative;
    width: 100%;
    .newCheckOutCalendar {
    }
    .newCheckOutSlot {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      .checkOutSlot {
        display: flex;
        flex-direction: column;
      }
    }
    .pricePerNight {
      margin-top: 25px;
    }
    .totalBeforeTax {
      margin-top: 25px;
    }
    .totalTax {
      margin-top: 35px;
    }
    .totalWithTax {
      margin-top: 35px;
    }
    .modifyDateBtns {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      // width: 100%;
      .cancel {
        border: none;
        background-color: transparent;
        font-family: var(--mavenpro);
        font-size: 16px;
        text-decoration: underline;
        color: black;
        font-weight: 500;
      }
      .extendBooking {
        border: none;
        // background-color: #00C690;
        background-image: var(--backgroundImg);
        font-family: var(--mavenpro);
        font-size: 15px;
        padding: 12px;
        border-radius: 5px;
        color: white;
        position: relative;
        width: 100%;
        // margin-left: 35px;
      }
    }
  }
  .cancel {
    position: relative;
    width: 100%;
    text-align: right;
    border: none;
    background-color: transparent;
    font-family: var(--mavenpro);
    font-size: 16px;
    text-decoration: underline;
    color: black;
    font-weight: 500;
    margin-top: 50px;
  }
}
