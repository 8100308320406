.configureTableWrapper{
    font-family: var(--mavenpro);
    .configureTableActions{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .removeBtn{
            margin-right: 15px;
            border: 1px solid #a5a5a5;
            border-radius: 7px;
            padding: 8px;
            font-family: var(--mavenpro);
            font-size: 15px;
            background-color: transparent;
            width: 125px;
        }
        .addTableBtn{
            border: none;
            border-radius: 7px;
            padding: 8px;
            font-family: var(--mavenpro);
            font-size: 15px;
            color: white;
            width: 100px;
            background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
        }
    }
}


// add table form modal
.addNewTableModal{
    border-radius: 25px;
    font-family: var(--mavenpro);
    padding: 25px;
    height: fit-content;
    width: 450px;
    overflow-x: hidden;
    color: black;
    .addTableWrapper{
        display: flex;
        flex-direction: column;
        margin-top: 7px;
        align-items: center;
        position: relative;
        width: 100%;
        .addTableHeader{
            font-weight: 500;
            font-size: 15px;
            padding-bottom: 7px;
            border-bottom: 1px solid #DDDDDD;
            width: 100%;
            text-align: center;
        }
        .addTableForm{
            margin-top:25px;
            align-items: center;
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            .addTableInpField{
                margin-top: 20px;
                font-family: var(--mavenpro);
                color: black;
            }
        }
        .btnWrapper{
            position: relative;
            margin-top: 25px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            padding: 25px;
            .cancel{
                border: none;
                font-family: var(--mavenpro);
                font-size: 15px;
                font-weight: 500;
                margin-top: auto;
                margin-bottom: auto;
                text-decoration: underline;
                background-color: transparent;
            }
            .confirmAdd{
                margin-top: auto;
                margin-bottom: auto;
                background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
                font-family: var(--mavenpro);
                font-size: 15px;
                font-weight: 500;
                border: none;
                color: white;
                border-radius: 5px;
                padding: 7px;
            }
        }
    }
}