.houseKeepingWrapper{
    font-family: var(--mavenpro);
    margin: 15px;
    .appBar{
        border: none;
        background-color: transparent;
        box-shadow: none;
        border-bottom: 1px solid rgb(148, 143, 143);
        width: 100%;
        .houseKeepingTabsWrapper{
            font-family: var(--mavenpro);
            border: none;
            background-color: transparent;
            .houseKeepingTab{
                font-family: var(--mavenpro);
                color: black;
                text-transform: capitalize;
                font-weight: 500;
                font-size: 15px;
            }
        }
    }
    .tabsContent{
        position: relative;
        // overflow-x: hidden;
        // overflow-y: visible;
    }
}
