.upgradeRoomsWrapper {
  position: relative;
  width: 100%;
  font-family: var(--mavenpro);
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  margin-left: 5rem;
  .modifyRoomType {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .chooseRoomId {
    margin-top: 25px;
  }
  .pricePerNight {
    margin-top: 25px;
  }
  .totalBeforeTax {
    margin-top: 25px;
  }
  .totalTax {
    margin-top: 25px;
  }
  .totalWithTax {
    margin-top: 25px;
  }
  .upgradeBtns {
    margin-top: 45px;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    justify-content: space-between;
    // width: 100%;
    .cancel {
      border: none;
      background-color: transparent;
      font-family: var(--mavenpro);
      font-size: 16px;
      text-decoration: underline;
      color: black;
      font-weight: 500;
    }
    .upgradeRoom {
      border: none;
      background-image: var(--backgroundImg);
      font-family: var(--mavenpro);
      font-size: 15px;
      border-radius: 5px;
      color: white;
      margin-left: 35px;
      width: 125px;
      height: 40px;
      margin-right: 11rem;
    }
  }
}
