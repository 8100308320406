.buttonHelper{
    text-transform: capitalize;
    font-size: 15px;
    color: white;
    font-family: var(--mavenpro);
    background-image: var(--backgroundImg);
    border: none;    
    height: 40px;
    width: 100px;
}

.outlinedCustomButton{
    width: fit-content;
    height: 40px;
    padding-right: 12px;
    padding-left: 12px;
    color: black;
    background-color: transparent;
    border: 2.5px solid black;
    border-radius: 5px;
}