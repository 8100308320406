.splitRoomWrapper{
    font-family: var(--mavenpro);
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    position: relative;
    width: 100%;
    .chooseRoomType{

    }
    .splitRoomCost{
        margin-top: 25px;
    }
    .splitDate{
        margin-top: 35px;
    }
    .splitSlot{
        margin-top: 35px;
    }
    .newRoomId{
        margin-top: 35px;
    }
}
.splitBtns{
    margin-top: 45px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    width: 100%;
    .cancel{
        border: none;
        background-color: transparent;
        font-family: var(--mavenpro);
        font-size: 16px;
        text-decoration: underline;
        color: black;
        font-weight: 500;
    }
    .upgradeRoom{
        border: none;
        background-image: var(--backgroundImg);
        font-family: var(--mavenpro);
        font-size: 15px;
        padding: 10px;
        border-radius: 5px;
        color: white;
        margin-left: 35px;
    }
}