.roomtypeWrapper{
    font-family: var(--mavenpro);
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    // justify-content: flex-start;
    justify-content: space-between;
    margin-bottom: -55px;
    // margin-right: 25px;
    .roomDataWrapper{
        display: flex;
        flex-direction: column;
        margin-top: 35px;
        height: max-content;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0px 0px 15px 6px #0000001a;
        // padding-right: 15px;
        .mock-loader{
            width: 100%;
            height: auto;
            border-radius: 10px 10px 0px 0px;
            background-color: #f6f7f8;
            max-width: initial;
            .box{
                border-radius: 0.5rem;
                animation-duration: 1s;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-name: placeHolderShimmer;
                animation-timing-function: linear;
                background: #f6f7f81a;
                background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
                background-size: 1000px 104px;
                position: relative;
                overflow: hidden;
              }
          }
        .editIcon{
            position: absolute;
            cursor: pointer;
            margin-top: 10px;
            margin-left: 10px;
            z-index: 90;
            background-color: white;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            text-align: center;
            padding-top: 5px;
        }
        .moreOptionBox{
            position: absolute;
            width: 100px;
            background-color: white;
            right: 31px;
            box-shadow: 0px 0px 10px 6px #0000001a;
            left: auto;
            border-radius: 5px 0px 5px 5px;
            margin-top: 39px;
            padding: 5px;
        }
        .roomTypeImage{
            width: 100%;
            height: 27vh;
            overflow: hidden;
            .roomTypeImg{
                height: 27vh;
                width: 100%;
                object-fit: cover;
                border-radius: 10px 10px 0px 0px;
            }
            .defaultRoomTypeImg{
                height: 27vh;
                width: 100%;
                object-fit: cover;
                border-radius: 10px 10px 0px 0px;
                // filter: grayscale(75%);
            }
        }
        .editRoomType{
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
                border-radius: 5px;
                background-color: white;
                box-shadow: 0px 0px 10px 6px #0000001a;
            margin-top: -15px;
            .editRoomTypeBtn{
                // margin-right: 10px;
                border: none;
                // background-color: white;
                color: black;
                font-weight: 400;
                border-radius: 5px;
                font-family: var(--mavenpro);
                font-size: 15px;
                // background-color: white;
                padding: 5px 10px;
                height: 30px;
            }
            // .editRoomTypeBtn:hover{
            //     margin-right: 15px;
            //     border: 1px solid white;
            //     color: black;
            //     font-family: var(--mavenpro);
            //     font-size: 15px;
            //     background-color: transparent;
            //     padding: 5px;
            //     border-radius: 5px;
            //     height: 35px;
            // }
        }
        .roomTypeInfoWrapper{
            margin-top: 5px;
            padding: 0px 10px;
            display: flex;
            flex-direction: row;
            // display: flex;
            // flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-start;
            .roomTypeName{
                font-family: var(--mavenpro);
                font-size: 18px;
                font-weight: 500;
                margin-top: auto;
                margin-bottom: auto;
            }
            .isVirtual{
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 15px;
                font-size: 15px;
                font-weight: 500;
            }
        }
        .roomTypePrice{
            font-size: 16px;
            font-weight: 400;
            margin-top: 7px;
        }
        .occupancyDetails{
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            padding: 0px 10px;
            .detail{
                margin-top: 2px;
                font-family: var(--mavenpro);
                font-size: 15px;
                display: flex;
                flex-direction: row;
                background-color: transparent;
                .detailHeader{
                    margin-top: auto;
                    margin-bottom: auto;
                    font-weight: 300;
                    color: 'grey';
                    background-color: transparent;
                }
                .detailValue{
                    margin-top: auto;
                    margin-bottom: auto;
                    font-weight: 500;
                    margin-left: 12px;
                    background-color: transparent;
                }
            }
        }
        .roomTypeCta{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: 25px;
            .hideRoomButton{
                margin-top: auto;
                margin-bottom: auto;
                cursor: pointer;
                .hideRoomText{
                    position: relative;
                    border-radius: 5px;
                    color: white;
                    background-color: black;
                    font-family: var(--mavenpro);
                    padding: 5px;
                    font-size: 12px;
                }
                .hideRoomBtn{
                    font-family: var(--mavenpro);
                    font-size: 15px;
                    color: grey;
                    border: 1px solid #DDDDDD;
                    border-radius: 7px;
                    padding: 7px;
                    width: 125px;
                    height: 40px;
                }
            }
            .deleteRoom{
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 15px;
                cursor: pointer;
                .deleteRoomBtn{
                    font-family: var(--mavenpro);
                    font-size: 15px;
                    background-color: black;
                    color: white;
                    border-radius: 7px;
                    padding: 7px;
                    width: 125px;
                    height: 40px;
                    border: none;
                }
            }
            .roomAmenities{
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 15px;
                cursor: pointer;
                .roomAmenitiesBtn{
                    font-family: var(--mavenpro);
                    font-size: 15px;
                    background-color: black;
                    color: white;
                    border-radius: 7px;
                    padding: 7px;
                    width: 125px;
                    height: 40px;
                    border: none;
                }
            }
        }
    }
}


// room amenities modal 
.roomAmenitiesModalWrapper{
    font-family: var(--mavenpro);
    width: 550px;
    max-height: 90%;
    min-height: fit-content;
    padding: 5px;
    border-radius: 8px;
    align-items: center;
    .roomAmenitiesModalHeader{
        margin-top: 15px;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
    }
    .roomAmenitiesListWrapper{
        display: flex;
        align-items: center;
        
    }    
}



.roomAmenitiesModalWrapper::-webkit-scrollbar{
    display: none;
}

.react-photo-gallery--gallery {
    width: 100%;
}

.photoItem{
   z-index: 10000;
   top: 0 !important;
   width: 150px !important
}

.editable:empty:before  {
    content : attr(data-placeholder);
    color : grey !important
} 

.editable {
     color: '#222'
}