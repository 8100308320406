.flashManagerReportWrapper {
  margin: 0px 5px 5px 5px;
  font-family: var(--mavenpro);
  .infoWrapper {
    display: flex;
    margin-bottom: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-top: 25px;
    .calIcon {
      margin-top: auto;
      margin-bottom: auto;
    }
    .calDate {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 10px;
      margin-top: 2px;
    }
    .calendarFilterDisplay {
      border: 1px solid black;
      // padding: 12px;
      font-size: 15px;
      color: black;
      cursor: pointer;
      border-radius: 5px;
      width: 175px;
      height: 35px;
      text-align: center;
      padding-top: 5px;
    }
    .calendarFilter {
      border: none;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      position: absolute;
      height: fit-content;
      margin-top: 45px;
      z-index: 100;
      border-radius: 12px;
      background-color: white;
      .flashManagerCalendar {
        border-radius: 12px;
        font-family: var(--mavenpro);
        font-size: 15.5px;
        border: none;
        // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
      .buttonWrappers {
        // display: flex;
        // flex-direction: row;
        // justify-content: center;
        .cancelButton {
          margin-top: 25px;
          .cancelBtn {
            border: none;
            border-radius: 5px;
            font-family: var(--mavenpro);
            font-size: 14.5px;
            width: 125px;
            height: 45px;
            background-color: red;
            color: white;
          }
        }
      }
      .applyButton {
        margin-top: 25px;
        margin-left: 25px;
        .applyBtn {
          border: none;
          border-radius: 8px;
          font-family: var(--mavenpro);
          font-size: 14.5px;
          width: 125px;
          height: 45px;
          background-color: #121212;
          color: white;
        }
      }
    }
    .downloadReportBtn {
      cursor: pointer;
    }
    .reportName {
      width: 100%;
      margin-top: auto;
      font-size: 18px;
      margin-bottom: auto;
      font-weight: 400;
      text-align: center;
    }
  }
  .flashManagerReportData {
    .propData {
      margin-top: 10px;
    }
    .fmSubReport {
      margin-top: 15px;
    }
    .propRows {
      position: relative;
      width: 100%;
      display: grid;
      grid-template-columns: 40% 20% 20% 20%;
      font-family: var(--mavenpro);
      .rowHeader {
        font-family: var(--mavenpro);
        font-weight: 500;
      }
    }
    .alignCols {
      display: flex;
      justify-content: flex-end;
    }
    .multipleReports {
      position: relative;
      width: 100%;
      display: grid;
      grid-template-columns: 50% 50%;
      .inventoryReportRow {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .rowHeader {
          font-weight: 500;
          font-family: var(--mavenpro);
        }
      }
    }
    .subReportDataWrapper {
      border: none;
      border-radius: 5px;
      padding: 15px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin-bottom: 25px;
      z-index: 0;
      .subReportName {
        font-size: 20px;
        font-weight: 500;
      }
      .separator {
        margin: 15px 15px 15px 0px;
        border-bottom: 0.5px rgb(158, 130, 130) solid;
      }
      .subReportData {
        display: flex;
        flex-direction: row;
        .metricNameWrapper {
          font-size: 15px;
          width: 55%;
          display: flex;
          flex-direction: column;
          .subHeaders {
            margin-top: 12px;
            margin-bottom: 15px;
            font-weight: 500;
            font-size: 15px;
          }
          .total {
            font-weight: 500;
          }
        }
        .todayWrapper {
          font-size: 15px;
          width: 30%;
          display: flex;
          flex-direction: column;
          .subHeaders {
            margin-top: 12px;
            margin-bottom: 15px;
            font-weight: 500;
            font-size: 15px;
          }
          .total {
            font-weight: 500;
          }
        }
        .mtdWrapper {
          font-size: 15px;
          width: 40%;
          display: flex;
          flex-direction: column;
          .subHeaders {
            margin-top: 12px;
            margin-bottom: 15px;
            font-weight: 500;
            font-size: 15px;
          }
          .total {
            font-weight: 500;
          }
        }
        .ytdWrapper {
          font-size: 15px;
          width: 40%;
          display: flex;
          flex-direction: column;
          .subHeaders {
            margin-top: 12px;
            margin-bottom: 15px;
            font-weight: 500;
            font-size: 15px;
          }
          .total {
            font-weight: 500;
          }
        }
      }
    }
  }

  // printable scss
  .flashManagerReportDataPrintable {
    background-color: white;
    color: black;
    margin-left: 350px;
    .reportName {
      width: 100%;
      margin-top: auto;
      font-size: 18px;
      margin-bottom: auto;
      font-weight: 400;
    }
    .subReportDataWrapper {
      border: none;
      border-radius: 15px;
      padding: 15px;
      background-color: white;
      // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      z-index: 0;
      width: 835px;
      .subReportName {
        font-size: 18px;
        font-weight: 500;
        background-color: white;
      }
      .separator {
        margin: 15px 15px 15px 0px;
        // border-bottom: 0.5px rgb(158, 130, 130) solid;
        background-color: white;
      }
      .subReportData {
        display: flex;
        flex-direction: row;
        background-color: white;
        .metricNameWrapper {
          font-size: 14.5px;
          width: 55%;
          display: flex;
          flex-direction: column;
          .subHeaders {
            margin-top: 12px;
            margin-bottom: 15px;
            font-weight: 500;
            font-size: 14.5px;
          }
          .total {
            font-weight: 500;
          }
        }
        .todayWrapper {
          font-size: 14.5px;
          width: 30%;
          display: flex;
          flex-direction: column;
          margin-left: 100px;
          .subHeaders {
            margin-top: 12px;
            margin-bottom: 15px;
            font-weight: 500;
            font-size: 14.5px;
          }
          .total {
            font-weight: 500;
          }
        }
        .mtdWrapper {
          font-size: 14.5px;
          width: 45%;
          display: flex;
          flex-direction: column;
          .subHeaders {
            margin-top: 12px;
            margin-bottom: 15px;
            font-weight: 500;
            font-size: 14.5px;
          }
          .total {
            font-weight: 500;
          }
        }
        .ytdWrapper {
          font-size: 14.5px;
          width: 40%;
          display: flex;
          flex-direction: column;
          .subHeaders {
            margin-top: 12px;
            margin-bottom: 15px;
            font-weight: 500;
            font-size: 14.5px;
          }
          .total {
            font-weight: 500;
          }
        }
      }
    }
  }
}

// For Flash manager report table

.fmReportTable {
  position: relative;
  width: 100%;
  margin-top: 15px;
  border: 1px solid #efefef;
  border-radius: 5px;
  border-collapse: collapse;
  .colNames {
    position: relative;
    padding: 10px;
  }
}
.fmReportTable td {
  padding: 10px;
  border: 1px solid #efefef;
}

.fmReportTable td.right-aligned {
  position: relative;
  text-align: right;
}

.fmReportTable th {
  position: relative;
  text-align: center;
  border: 1px solid #efefef;
}
