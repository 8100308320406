.addProductsTemplateWrapper{
    font-family: var(--mavenpro);
    margin-top: 25px;
    margin-right: 10px;
    border: none;
    // background-color: transparent;
    // display: flex;
    // flex-direction: row;
    width: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: transparent;
    border-radius: 5px;
    // padding: 15px;
    .pImage{
        width: 275px;
        height: 175px;
        overflow: hidden;
        background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
        border-radius: 5px;
        position: relative;
        .productReplacement{
            color: white;
            font-family: var(--mavenpro);
            border-radius: 5px;
            min-height: 175px;
            height: auto;
            width: 275px;   
        }
        .prodDisplayImg{
            border-radius: 5px;
            width: auto;
            height: 175px;
        }
    }
    .productInfoWrapper{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        .infoWrapper{
            display: flex;
            flex-direction: row;
            .productName{
                margin-top: auto;
                margin-bottom: auto;
                font-weight: 500;
                font-size: 17px;
            }
            .serviceEnabled{
                background-color: #121212;
                font-size: 11.5px;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 17.5px;
                border: none;
                border-radius: 8px;
                padding: 2px;
                padding-left: 5px;
                padding-right: 5px;
                color: white;
            }
        }
        .productUnits{
            color: red;
            font-size: 11.5px;
            margin-top: 5px;
            margin-bottom: auto;
        }
        .cat{
            font-size: 12px;
            margin-top: 7px;
            font-weight: 500;
        }
        .prodPrice{
            margin-top: 7px;
            font-size: 14px;
            font-weight: 400;
            .selfServicePrice{
                display: flex;
                flex-direction: row;
                .price{
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 15px;
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
    }
    .editProductWrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // margin-left: 275px;
        text-align: right;  
        position: absolute;
        // right: 0;
        .editProd{
            left: 0;
            cursor: pointer;
            margin-left: 12px;
            margin-top: 10px;
        }
        .deleteProd{
            right: 0;
            margin-left: 180px;
            cursor: pointer;
            margin-top: 10px;
        }
    }
}

