.cartItemsWrapper{
    font-family: var(--mavenpro);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .cartItemHeader{
        margin-top: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #DDDDDD;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .cartItems{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 10px;
        font-family: var(--mavenpro);
        font-size: 14.5px;
        align-items: center;
        height: 80%;
        .itemName{
            width: 200px;
            text-align: left;
            margin-top: auto;
            margin-bottom: auto;
            font-family: var(--mavenpro);
            margin-left: 25px;
        }
        .itemPrice{
            margin-left: 12px;
            width: 105px;
            text-align: left;
            margin-top: auto;
            margin-bottom: auto;
            font-family: var(--mavenpro);
        }
        .itemQty{
            margin-left: 12px;
            width: 65px;
            overflow: hidden;
            margin-top: auto;
            margin-bottom: auto;
            .editablePriceField{
                border: none;
                border-bottom: 1px solid black;
                padding: 5px;
                font-size: 14.5px;
                font-family: var(--mavenpro);
                width: 65px;
            }
            .editablePriceField:focus{
                outline: none;
            }
        }
        .deleteItem{
            margin-top: auto;
            margin-bottom: auto;
            width: fit-content;
            text-align: right;
            cursor: pointer;
        }
    }
    .orderSummary{
        position: absolute;
        bottom: 0;
        left:0;
        right:0;
        padding-top: 12px;
        padding-bottom: 25px;
        display: flex;
        flex-direction: column;
        border-top: 1px solid #DDDDDD;
        .summary{
            display: flex;
            flex-wrap: wrap;
            margin-top: 12px;
            justify-content: space-between;
            font-family: var(--mavenpro);
            font-size: 15px;
            .sumHeaderName{
                font-weight: 500;
                margin-left: 25px;
            }
            .sumHeaderVal{
                font-weight: 400;
                margin-right: 25px;
            }
        }
        .invoiceCheckoutButton{
            position: relative;
            margin: 25px;
            align-items:center;
            margin-bottom: 45px;
            .invoiceCheckoutBtn{
                border: none;
                border-radius: 5px;
                font-family: var(--mavenpro);
                font-size: 13.5px;
                color: white;
                height: 45px;
                left:0;
                right:0;
                width: 100%;
                margin-bottom: 25px;
                position: absolute;
                background-image: linear-gradient(-150deg, #1ab394, #1a91ae);

            }
        }
    }
}