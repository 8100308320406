.editModalWrapper{
    font-family: var(--mavenpro);
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 100%;
    position: relative;
    .editCustomerHeader{
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }
    .sep{
        margin-top: 15px;
        width: 100%;
        border-top: 1px solid rgb(196, 196, 196);
    }
    .editCustomerDetails{
        display: flex;
        flex-direction: row;
        // justify-content: space-around;
        justify-content: space-between;
        padding: 25px;
        flex-wrap: wrap;
        .customerName{
            margin-top: 45px;
        }
        .customerEmail{
            margin-top: 45px;
        }
        .customerMobile{
            margin-top: 45px;
        }
        .customerAddress{
            margin-top: 45px;
        }
        .customerCountry{
            margin-top: 45px;
        }
        .customerState{
            margin-top: 45px;
        }
        .customerCity{
            margin-top: 45px;
        }
        .customerZip{
            margin-top: 45px;
        }
        .customerGovId{
            margin-top: 45px;
        }
        .customerGovIdNum{
            margin-top: 45px;
        }
        .customerGovIdImg{
            margin-top: 45px;
            & legend{
                visibility: visible;
                width: 250px;
            }
        }
    }
    .addServiceBtns{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 45px;
        .cancelBtn{
            border: none;
            background-color: transparent;
            text-decoration: underline;
            font-family: var(--mavenpro);
            font-size: 15px;
            cursor: pointer;  
            font-weight: 500;
        }
        .addBtn{
            border-radius: 5px;
            // background-color: #24FAC0;
            background-image: var(--backgroundImg);
            font-family: var(--mavenpro);
            border: none;
            color: white;
            font-size: 14px;
            font-weight: 500;
            padding: 12px 20px 12px 20px;
        }
    }   
}