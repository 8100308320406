.hotelLogoWrapper {
  position: relative;
}
.hotelLogo {
  max-height: 150px;
  max-width: 250px;
  cursor: pointer;
  margin-left: 2rem;
  margin-top: 3rem;
}
.hotelImage {
  cursor: pointer;
  margin-left: 2rem;
  margin-top: 3rem;
  width: 32%;
  border-radius: 10px;
}

.contentWrapper {
  position: relative;
  width: 65%;
}

.visaLogo {
  max-height: 60px;
  max-width: 200px;
  cursor: pointer;
}

.amexLogo {
  max-height: 60px;
  max-width: 200px;
  cursor: pointer;
  mix-blend-mode: darken;
  margin-right: 1rem;
  margin-left: 1rem;
}

.pciLogo {
  max-height: 60px;
  max-width: 200px;
  cursor: pointer;
  mix-blend-mode: darken;
}

.maestroLogo {
  max-height: 60px;
  max-width: 200px;
  cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .hotelLogoWrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .hotelLogo {
    max-height: 150px;
    max-width: 250px;
    cursor: pointer;
    margin-left: 0rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
  .hotelImage {
    display: none;
    width: 0;
  }
  .contentWrapper {
    position: relative;
    width: 100%;
  }
  .visaLogo {
    max-height: 35px;
    max-width: 100px;
    cursor: pointer;
  }
  .amexLogo {
    max-height: 35px;
    max-width: 100px;
    cursor: pointer;
    mix-blend-mode: darken;
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .pciLogo {
    height: 35px;
    width: 100px;
    cursor: pointer;
    mix-blend-mode: darken;
  }
  .maestroLogo {
    height: 35px;
    width: 50px;
    cursor: pointer;
  }
}
