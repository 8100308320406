.ticket {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
td,
th,
tr,
table {
  border-top: 1px solid black;
  border-collapse: collapse;
}

td.description,
th.description {
  width: 38%;
  max-width: 38%;
  word-break: break-all;
  font-size: 8px;
}

td.item,
th.item {
  width: 28%;
  max-width: 28%;
  word-break: break-all;
  font-size: 8px;
}

td.qty,
th.qty {
  width: 20%;
  max-width: 20%;
  word-break: break-all;
  font-size: 8px;
}

th.price,
td.price {
  width: 28%;
  max-width: 28%;
  word-break: break-all;
  font-size: 8px;
  align-items: center;
  align-self: center;
}

th.total {
  display: flex;
  width: 30%;
  max-width: 30%;
  word-break: break-all;
  font-size: 8px;
}

td.total {
  display: flex;
  width: 30%;
  max-width: 30%;
  word-break: break-all;
  font-size: 8px;
}

.rule {
  width: 220;
  max-width: 250;
  font-size: 8px;
}

.centered {
  text-align: center;
  align-content: center;
  align-self: center;
}

.hotelInfo {
  position: relative;
}

.hotelInfo p {
  position: relative;
  width: 100%;
}

img {
  max-width: inherit;
  width: inherit;
}

@media print {
  // .hidden-print,
  // .hidden-print * {
  //     display: none !important;
  // }
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
  body {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}
