.shopConfigurationWrapper{
    font-family: var(--mavenpro);
    margin: 25px;
    .headers{
        display: flex;
        flex-direction: row;
        .headerShopName{
            font-size: 22px;
            font-weight: 500;
            background-color: transparent;
        }
        .subHeader{
            font-size: 18px;
            font-weight: 400;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 15px;
        }
    }
    .prodSearchAddWrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 50px;
        position: relative;
        align-items: center;
        border: none;
        .searchBar{
            // background-color: white;
            //                     border: 2px solid #DDDDDD;
            position: absolute;
            border: none;
            border-radius: 35px;
            width: 850px;
            height: 64.5px;
            color: black;
            font-size: 18px;
            padding-left: 25px;
            border: 1px solid #DDDDDD;
            background-color: transparent;
            // box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
        }
        .searchBar::placeholder{
            margin-left: 45px;
            font-size: 18px;
            // color: rgb(94, 88, 88);
            // color: black;;
        }
        .searchBar:focus{
            outline: none;
        }
        .filterContentWrapper{
            position: absolute;
            display: flex;
            flex-direction: row;
            margin-left: 525px;
            // right: 0;
        }
        .separator{
            height: 40px;
            border-left: 0.75px solid black;
            margin-left: 5px;
            margin-right: 5px;
            margin-top: auto;
            margin-bottom: auto;
            position: relative;
        }
        .applyFilters{
            color: black;
            position: relative;
            .applyFiltersBtn{
                border: none;
                font-family: var(--mavenpro);
                font-size: 17px;
                font-weight: 500;
                color: black;
                height: 65px;
                background-color:transparent;
                text-decoration: underline;
            }
        }
        .filterSeparator{
            height: 40px;
            border-left: 0.75px solid #DDDDDD;
            margin-left: 150px;
            margin-right: 7.5px;
            margin-top: auto;
            margin-bottom: auto;
            position: relative;
        }
        .addProducts{
            color: black;
            position: relative;
            .addProductsBtn{
                border: none;
                font-family: var(--mavenpro);
                font-size: 17px;
                font-weight: 500;
                color: black;
                height: 65px;
                background-color:transparent;
                text-decoration: underline;
            }
        }
    }
    .displayAddedProductsWrapper{
        margin: auto;
        margin-top: 75px;
        border: none;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: fit-content;
        // display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
        // grid-template-columns: auto auto;
    }
}

// add product modal
.addProdModal{
    border-radius: 5px;
    font-family: var(--mavenpro);
    padding: 15px;
    height: 85%;
    width: fit-content;
    overflow-x: hidden;
    overflow-y: scroll;
    color: black;
    .addProductWrapper{
        margin-top: 7px;
        .productHeader{
            font-size: 16px;
            border-bottom: 1px solid black;
            padding-bottom: 15px;
            text-align: center;
            font-weight: 500;
        }
        .basicInfoWrapper{
            margin-top: 35px;
            border-bottom: 1px solid #DDDDDD;
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            justify-content: center;
            padding-left: 25px;
            padding-right: 25px;
            padding-bottom: 45px;
            .basicInfoFormWrapper{
                align-items: center;
                display: grid;
                grid-template-columns: auto auto;
                .addProdFields{
                    margin-top:35px;
                    margin-right: 15px;
                }
            }
        }
        .addCategory{
            margin-top: 45px;
            font-size: 12px;
            color: black;
            text-align: center;
            padding-left: 25px;
            padding-right: 25px;
            .addNewCat{
                text-decoration: underline;
                cursor: pointer;
                font-weight: 500;
                color: black;
                font-size: 15px;
                font-family: var(--mavenpro);
            }
            .addNewCat:hover{
            color: #1AB394;
            }
        }
    }
    .addProdWrapper{
        margin: 15px;
        margin-top: 55px;
        margin-bottom: 25px;
        display: grid;
        grid-template-columns: auto auto;
        .cancelButton{
            align-items: flex-start;
            .cancelBtn{
                font-family: var(--mavenpro);
                font-size: 15px;
                font-weight: 500;
                text-decoration: underline;
                background-color: transparent;
                color: black;
                border: none;
                margin-top: 7.5px;
            }
        }
    }
    .addProdButton{
        text-align: right;
        .addProdBtn{
            border-radius: 5px;
            width: 125px;
            background-color: #121212;
            color: white;
            height: 50px;
            border: none;
            font-size: 15px;
            font-family: var(--mavenpro);
            background-image: var(--backgroundImg);
        }
    }
}

.addProdModal::-webkit-scrollbar{
    display: none;
}


// add category modal
.addCatModal{
    border-radius: 5px;
    font-family: var(--mavenpro);
    padding: 25px;
    height: fit-content;
    width: fit-content;
    overflow-x: hidden;
    overflow-y: scroll;
    color: black;
    .addCategoryWrapper{
        margin-top: 7px;
        .addCatHeader{
            font-size: 20px;
            border-bottom: 1px solid black;
            padding-bottom: 15px;
            text-align: center;
        }
        .addCatFormWrapper{
            margin-top: 45px;
            .labelInputWrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin-bottom: 25px;
                .label{
                    justify-content: flex-start;
                    font-family: var(--mavenpro);
                    font-size: 17px;
                    align-self: flex-start;
                    width: 100%;
                    // margin-left: 25px;
                    background-color: transparent;
                    margin-bottom: 15px;
                    color: black;
                    margin-left: 65px;
                }
                .addCatInput{
                    border: 2px solid #DDDDDD;
                    border-radius: 12px;
                    background-color: transparent;
                    padding: 18px;
                    font-size: 15px;
                    width: 85%;
                }
                .addCatInput:focus{
                    outline: none;
                }
            }
        }
        .separator{
            width: 100%;
            border-top: 1px solid black;
            margin-top: 45px;
        }
        .addCatWrapper{
            margin: 15px;
            margin-top: 45px;
            margin-bottom: 10px;
            display: grid;
            grid-template-columns: auto auto;
            .backButton{
                align-items: flex-start;
                .backBtn{
                    font-family: var(--mavenpro);
                    font-size: 15px;
                    text-decoration: underline;
                    background-color: transparent;
                    color: black;
                    border: none;
                    margin-top: 7.5px;
                    font-weight: 500;
                }
            }
            .addCatButton{
                text-align: right;
                .addCatBtn{
                    border-radius: 12px;
                    width: 150px;
                    background-image: var(--backgroundImg);
                    // background-color: #121212;
                    color: white;
                    padding: 12px;
                    height: 50px;
                    border: none;
                    font-size: 15px;
                }
            }
        }
    }
}

.addCatModal::-webkit-scrollbar{
    display: none;
}
