.editHotelDetailsWrapper{
    font-family: var(--mavenpro);
    display: flex;
    flex-direction: column;
    .editPropDetailsWrapper{
        margin-top: 5px;
        padding: 0px 35px;
        margin-bottom: 5px;
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .cancelBtn{
            border: none;
            font-family: var(--mavenpro);
            font-size: 15px;
            font-weight: 500;
            background-color: transparent;
            text-decoration: underline;
            color: black;
        }
        .editHeader{
            text-align: center;
            font-size: 15px;
            font-weight: 500;
            margin-top: 12px;
            margin-bottom: 12px;
        }
        .saveBtn{
            border: none;
            background-image: var(--backgroundImg);
            padding: 5px;
            border-radius: 7px;
            font-family: var(--mavenpro);
            color: white;
            font-weight: 500;
            font-size: 15px;
            width: 80px;   
        }
    }
    
    .editPropDetails{
        margin: 10px 35px 5px 35px;
        .editDetailType{
            font-size: 25px;
            font-weight: 500;
            margin-bottom: 15px;
        }
        .propEdits{
            // display: grid;
            // grid-template-columns: auto auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            flex-direction: row;
            .editField{
                margin-top: 35px;
                .phoneInputClass{
                    width: 250px;
                    height: 50px;
                    font-size: 15px;
                    font-family: var(--mavenpro);
                }
            }
        }
        
    }
}

.css-1s2u09g-control{
    min-height: 50px !important;
    border-color: #0000003b !important;
}

.css-1pahdxg-control {
    min-height: 50px !important;
    border-color: #1ab394 !important;
    box-shadow: none !important;
}

.css-26l3qy-menu{
    z-index: 20000;
}