// View booking cash payment modal
.settleDuesCtaBtn {
  margin-left: 10px;
  border: 1px solid #dddddd;
  background-color: transparent;
  font-size: 15px;
  font-family: var(--mavenpro);
  height: 35px;
  min-width: 120px;
  width: 150px;
  border-radius: 5px;
}

// Settle dues listener content
.settleDuesContent {
  position: relative;
  text-align: left;
  .settleDuesInfo {
    font-family: var(--mavenpro);
    font-size: 15px;
    margin-top: 7.5px;
  }
}

// Confirmation modal for view bookings
.confirmationHeader {
  position: relative;
  width: 100%;
  font-family: var(--mavenpro);
  font-size: 15px;
}
.horizontalRule {
  border: 1px solid #dddddd;
  margin-top: 3px;
  margin-bottom: 3px;
  position: relative;
  width: 100%;
}
.ctaWrapper {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 30% 20%;
}
.headerTitle {
  font-weight: 500;
  font-family: var(--mavenpro);
  font-size: 15px;
}
.ctaInfo {
  font-family: var(--mavenpro);
  font-size: 15px;
}
.ctaAmountInfo {
  font-size: 15px;
  font-weight: 500;
  text-decoration: underline;
}

// Bulk settle dues button
.bulkSettleDuesBtn {
  height: 45px;
  width: 120px;
  font-size: 15px;
  background-image: var(--backgroundImg);
  border: none;
  border-radius: 5px;
  color: white;
}

// Horizontal rule
.horizontalRule {
  margin-top: 3px;
  margin-bottom: 3px;
  border-bottom: 1px solid #dddddd;
  position: relative;
  width: 100%;
}
