.reportContent{
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    .reportTypeWrapper{
        // border: 1px solid #DDDDDD;
        border-radius: 5px;
        font-family: var(--poppins);
        font-size: 15px;
        position: relative;
        height: 250px;
        width: 275px;
        padding: 10px;
        overflow: hidden;
        text-align: left;
        background-color: #ffffff;
        .reportTypeImg{
            margin-top: 10px;
            .reportIconImg{
                border: none;
                width: 35px;
                height: 35px;
            }
        }
        .reportNameWrapper{
            margin-top: 10.5px;
            font-weight: 500;
            font-size: 14px;
        }
        .reportSummary{
            margin-top: 7.5px;
            width:250px; 
            font-weight: 400;
            font-size: 12.5px;
        }
        .viewReport{
            position: absolute;
            bottom: 0;
            margin-bottom: 7.5px;
        }
    }
}