.modal {
    height: 100%;
    flex-direction: row-reverse;
    animation: openModal 0.2s ease-in;
  }
  .myModalBodyssss {
    margin: 0 0 0 auto; 
    max-width: 50vw;
    height: 100vh;
   
  }
  .myModalContentsss {
    border-radius: 0;
    height: 100vh;
    overflow-y: auto;
  }
  
  @keyframes openModal {
    0% {
      transform: translate(60vw, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  