.houseKeepingGridWrapper{
    font-family: var(--mavenpro);
    color: black;
    font-size: 15px;
    .hkGridFilters{
        display: flex;
        flex-direction: row;
        margin-top: 25px;
        position: relative;
        .staffFilterWrapper{
            display: flex;
            flex-direction: column;
            position: relative;
            .filterName{
                margin-bottom: 12px;
                font-size: 17px;
                font-weight: 500;
            }
            .staffFilter{
                width: 550px;
                height: 55px;
                padding: 12px;
                border-radius: 12px;
                font-size: 15.5px;
                background-color: transparent;
                border: 2px solid #DDDDDD;
            }
            .staffFilterNote{
                margin-top: 12px;
                font-size: 12px;
                margin-bottom: auto;
            }
        }
        .roomTypeFilterWrapper{
            margin-left: 45px;
            .filterName{
                margin-bottom: 12px;
                font-size: 17px;
                font-weight: 500;
            }
            .roomTypeFilter{
                width: 550px;
                height: 55px;
                padding: 12px;
                border-radius: 12px;
                font-size: 15.5px;
                background-color: transparent;
                border: 2px solid #DDDDDD;
            }
        }
    }
    .roomsWrapper{
        position: relative;
        margin-top: 100px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
    }
}
