.panelWrapper {
  padding: 5px;
  padding-top: 10px;
  overflow: hidden;
  .panelMembers {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    width: 350px;
    max-height: 750px;
    overflow-y: scroll;
    overflow-x: hidden;
    justify-content: center;
    text-align: center;
    font-family: var(--mavenpro);
    flex-wrap: wrap;
    .panelMembers {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
    }
    .memberWrapper {
      margin-top: 25px;
      margin-bottom: 25px;
      .memberName {
        margin-top: 5px;
        flex-wrap: wrap;
        white-space: normal;
      }
      .memberImg {
        height: 15px;
        width: 15px;
        color: red;
      }
    }
  }
  .panelMembers::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .panelMembers {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .verticalAxis {
    position: relative;
    width: 100%;
    border-top: 1px solid #f1f1f1;
  }
}

// quick panel
.profileDetailsWrapper {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  .profileImg {
    border-radius: 5px;
    width: 50px;
    height: 50px;
    text-align: center;
    font-family: var(--mavenpro);
    font-size: 20px;
    background-color: #e1f0ff;
    padding-top: 12.5px;
    margin-right: 15px;
  }
  .profileDetails {
    .hotelName {
      font-size: 15px;
    }
    .hotelEmail {
      margin-top: 5px;
      font-size: 12.5px;
    }
  }
}
.userDropdownWrapper {
  width: 300px;
  overflow: hidden;
}
.profileWrapper {
  display: flex;
  flex-direction: column;
  font-family: var(--mavenpro);
  font-weight: 500;
  color: black;
  .profileListItems {
    list-style-type: none;
    .profileItem {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }
}
