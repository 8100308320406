.invoiceWrapper {
  position: relative;
  width: 100%;
  font-family: var(--mavenpro);
  // margin-top: 10px;
  font-size: 15px;
  .hotelDetailsWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .hotelDetails {
      display: flex;
      flex-direction: row;
      .avatar {
        border-radius: 50%;
        overflow: hidden;
        height: 45px;
        width: 45px;
        color: white;
        background-image: var(--backgroundImg);
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        padding-top: 7px;
        margin-top: auto;
        margin-bottom: auto;
      }
      .basicInfo {
        margin-left: 15px;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 15px;
        max-width: 150px;
        width: fit-content;
        flex-wrap: wrap;
        .hotelName {
          position: relative;
          width: 100%;
          // flex-wrap: wrap;
          // word-break: break-all;
        }
        .hotelAddress {
          position: relative;
          width: 100%;
          flex-wrap: wrap;
        }
      }
    }
    .folioInfo {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      .folioId {
        margin-top: auto;
        margin-bottom: auto;
        font-weight: 500;
        // cursor: pointer;
        word-break: break-all;
      }
      .folioActionWrapper {
        margin-top: auto;
        margin-bottom: auto;
        .folioActionBtn {
          background-color: #f8f8f8;
          margin-left: 15px;
          cursor: pointer;
          font-family: var(--mavenpro);
          border: none;
          border-radius: 50%;
          padding: 2.5px;
        }
      }
      .folioActions {
        background-color: transparent;
        font-family: var(--mavenpro);
        .item {
          margin-top: 10px;
          font-size: 15px;
          cursor: pointer;
        }
        .horizontalLine {
          border-top: 1px solid #dddddd;
          margin-top: 10px;
        }
        .item:hover {
          padding: 0px;
          background-color: #dddddd;
          margin-top: 10px;
          font-size: 15px;
          cursor: pointer;
        }
      }
      .reloadBooking {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 15px;
        // margin-right: 17.5px;
        cursor: pointer;
      }
    }
  }
  .horizontalLine {
    border-top: 1px solid #dddddd;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .customerDetailsWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    // margin-left: 25px;
    .basicCustInfo {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      .custDetailHeader {
        font-size: 17.5px;
        font-weight: 500;
        margin-bottom: 10px;
        margin-right: 10px;
        font-family: var(--mavenpro);
      }
      .nameSource {
        font-size: 17.5px;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // .custName {
        //   margin-top: auto;
        // }
      }
    }
    .customerDetail {
      position: relative;
      width: 100%;
      display: grid;
      grid-template-columns: 60% 40%;
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      margin-top: 7.5px;
      .details {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 15px;
        // margin-left: 10px;
        width: 100%;
        .detail {
          position: relative;
          width: 100%;
          .custSubHeading {
            width: 27.5%;
            font-size: 12.5px;
            font-weight: 700;
            // color: #9f8787;
            color: black;
            flex-wrap: wrap;
            position: relative;
          }
          .custSubVal {
            width: 70%;
            font-size: 12.5px;
            font-weight: 700;
            flex-wrap: wrap;
            position: relative;
            word-wrap: break-word;
          }
        }
      }
    }
  }
  .invoiceTableActions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: #f8f8f8;
    padding-left: 10px;
    padding-top: 15px;
    .invoiceSummTableWrapper {
      position: relative;
      width: 100%;
      .filtersWrapper {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .searchBar {
          margin-top: auto;
          margin-bottom: auto;
        }
        .actionsWrapper {
          margin-top: auto;
          margin-bottom: auto;
          display: flex;
          flex-direction: row;
          .removeButton {
            margin-bottom: auto;
            margin-top: auto;
            cursor: pointer;
          }
          .otherActions {
            margin-top: auto;
            margin-bottom: auto;
            .otherActionsButton {
              border: none;
              background-color: #dddddd;
              padding-right: 5px;
              padding-left: 5px;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              width: fit-content;
              border-radius: 5px;
              // margin-left: 10px;
            }
            .tableActions {
              font-size: 13.5px;
              .createPOSOrder {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
    .servicesTable {
      // margin-top: 25px;
      .invoiceSummaryTable {
        position: relative;
        width: 98.5%;
      }
    }
  }
}

// Toggle between multiple invoice modes
.toggleAdvanced {
  font-family: var(--mavenpro);
  font-size: 15px;
  color: black;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 25px;
  .labelText {
    font-family: var(--mavenpro);
    font-size: 15px;
  }
}

// editing the customer identification number
.editCustIdBtn {
  display: flex;
  flex-direction: column;
  border: none;
  font-family: var(--mavenpro);
  font-size: 15px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  margin-left: -10px;
}
.editCustIdentification {
  display: flex;
  flex-direction: column;
  font-family: var(--mavenpro);
  .editField {
    border: none;
    font-family: var(--mavenpro);
    font-size: 15px;
    background-color: transparent;
    font-family: var(--mavenpro);
  }
  .editField:focus {
    outline: none;
  }
  .updateBtns {
    margin-top: 10px;
    border-top: 1px solid #dddddd;
    padding-top: 10px;
    position: relative;
    width: 100%;
    .updateId {
      border: none;
      font-size: 15px;
      height: 40px;
      padding: 5px;
      font-family: var(--mavenpro);
      background-image: var(--backgroundImg);
      color: white;
      border-radius: 5px;
      position: relative;
      width: 100%;
    }
  }
}

// opening the side drawer after clicking on the folio id
.folioUpdateWrapper {
  display: flex;
  flex-direction: column;
  font-family: var(--mavenpro);
  font-size: 15px;
  position: relative;
  width: 100%;
  .folioInformationActionWrapper {
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    .folioInformation {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 10px;
      .fId {
        font-weight: 500;
        margin-top: 10px;
      }
      .bookingSrc {
        margin-top: 5px;
      }
    }
    .closeToggleDrawer {
      margin-top: auto;
      margin-bottom: auto;
      cursor: pointer;
      margin-right: 10px;
    }
  }
}

// styling the tabs inside the drawer

.folioTabs {
  margin-top: 25px;
  position: relative;
  .appBar {
    border: none;
    background-color: transparent;
    box-shadow: none;
    border-bottom: 1px solid rgb(148, 143, 143);
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .invoiceWrapperTabs {
      font-family: var(--mavenpro);
      border: none;
      background-color: transparent;
    }
    .invoiceTab {
      font-family: var(--mavenpro);
      color: black;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 15px;
    }
  }
  .tabsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    // overflow: auto;
  }
}

// styling the tabs inside the drawer

.AccountfolioTabs {
  margin-top: 2px;
  position: relative;
  .appBar {
    border: none;
    background-color: transparent;
    box-shadow: none;
    border-bottom: 1px solid rgb(148, 143, 143);
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .invoiceWrapperTabs {
      font-family: var(--mavenpro);
      border: none;
      background-color: transparent;
    }
    .invoiceTab {
      font-family: var(--mavenpro);
      color: black;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 15px;
    }
  }
  .tabsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    // overflow: auto;
  }
}

// printable invoice
.printableInvoice {
  position: relative;
  width: 100%;
}

// folios confirmation modal
.foliosConfirmationModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: none;
  box-shadow: 24;
  font-family: var(--mavenpro);
  width: 450px;
  padding: 15px;
  .folioConfirmationWrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
    width: 100%;
    justify-content: center;
    .folioHeader {
      position: relative;
      width: "100%";
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .horizontalSeparator {
      margin-top: 3px;
      margin-bottom: 15px;
      border-bottom: 1px solid #dddddd;
      position: relative;
      width: 100%;
    }
    .folioConfContent {
      font-size: 15px;
      text-align: center;
      font-family: var(--mavenpro);
      font-size: 500;
    }
    .folioConfButtons {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 25px;
      .cancel {
        border: none;
        font-weight: 500;
        font-family: var(--mavenpro);
        font-size: 15px;
        background-color: transparent;
        margin-top: auto;
        margin-bottom: auto;
      }
      .approved {
        border: none;
        background-image: var(--backgroundImg);
        font-family: var(--mavenpro);
        font-size: 15px;
        color: white;
        width: 125px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: auto;
        margin-bottom: auto;
        height: 40px;
      }
    }
  }
}

// capture signature
// folios confirmation modal
.tocWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: none;
  box-shadow: 24;
  font-family: var(--mavenpro);
  width: 450px;
  padding: 15px;
  .tocContent {
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
    width: 100%;
    justify-content: center;
    .tocBtns {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 25px;
      .cancel {
        border: none;
        font-weight: 500;
        font-family: var(--mavenpro);
        font-size: 15px;
        background-color: transparent;
        margin-top: auto;
        margin-bottom: auto;
      }
      .approved {
        border: none;
        background-image: var(--backgroundImg);
        font-family: var(--mavenpro);
        font-size: 15px;
        color: white;
        width: 125px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: auto;
        margin-bottom: auto;
        height: 40px;
      }
    }
  }
}

// add service modal
.addServiceModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: none;
  box-shadow: 24;
  display: flex;
  flex-direction: column;
  font-family: var(--mavenpro);
  font-size: 15px;
  width: 450px;
  // height: 550px;
  overflow-y: scroll;
  align-items: center;
  // padding: 10px;
  .addServiceWrapper {
    display: flex;
    flex-direction: column;
    .serviceHeaderWrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;
      .serviceHeader {
        background-color: transparent;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 15px;
        font-weight: 500;
        .roomIdHeader {
          display: inline-grid;
          margin-left: 2rem;
          width: 100px;
        }
      }
      .cross {
        font-weight: 500;
        font-size: 25px;
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
      }
    }
    .horizontal {
      margin-top: 10px;
      margin-bottom: 10px;
      border-top: 1px solid #dddddd;
    }
    .invoiceInfoWrapper {
      display: flex;
      flex-direction: column;
      .infoField {
        margin-top: 12.5px;
      }
    }
  }
  .addServiceBtns {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 25px;
    .cancel {
      border: none;
      font-weight: 500;
      font-family: var(--mavenpro);
      font-size: 15px;
      background-color: transparent;
      margin-top: auto;
      margin-bottom: auto;
    }
    .approved {
      border: none;
      background-image: var(--backgroundImg);
      font-family: var(--mavenpro);
      font-size: 15px;
      color: white;
      width: 175px;
      border-radius: 5px;
      font-weight: 500;
      margin-top: auto;
      margin-bottom: auto;
      height: 45px;
    }
  }
}

.addServiceModal::-webkit-scrollbar {
  display: none;
}

// dropdown ctas
.sourceNameWrapper {
  margin-top: auto;
  margin-bottom: auto;
}
.ctaDropdownBtns {
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--mavenpro);
  font-size: 15px;
  margin-left: 7.5px;
  border: 2px solid black;
  border-radius: 7.5px;
  padding: 3.5px;
  padding-left: 5px;
  padding-right: 5px;
  color: black;
  font-weight: 500;
  min-width: 85px;
  width: fit-content;
  background-color: transparent;
  .editCustIdentification {
    display: flex;
    flex-direction: column;
  }
  .editCtaHeader {
    font-family: var(--mavenpro);
    font-size: 15px;
    color: black;
    padding: 2.5px;
    color: black;
  }
}
.ctaNames {
  margin-top: 10px;
  font-family: var(--mavenpro);
  font-size: 15px;
  cursor: pointer;
  background-color: transparent;
  font-weight: 400;
}
.ctaNames:hover {
  background-color: #dddddd;
  margin-top: 10px;
  font-family: var(--mavenpro);
  font-size: 15px;
  cursor: pointer;
  font-weight: 400;
}

// Color coding the bookign status
.bookingStatus {
  border-radius: 25px;
  min-width: 110px;
  width: fit-content;
  height: fit-content;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  font-family: var(--mavenpro);
  font-size: 12.5px;
  background-color: black;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  text-transform: uppercase;
  text-align: center;
}

// Bulk edit price --- advanced folio
.foliosConfirmationModal {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--mavenpro);
  font-size: 15px;
  padding: 10px;
  .updateHeadeWrapper {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 10px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .bulkUpdateHeader {
      font-weight: 500;
    }
    .close {
      font-family: var(--mavenpro);
      cursor: pointer;
      font-size: 20px;
      color: black;
    }
  }
  .folioConfirmationWrapper {
    margin-top: 10px;
  }
  .displayCalendarBtn {
    height: 50px;
    position: relative;
    width: 100%;
    border: 1px solid #dddddd;
    border-radius: 7.5px;
    background-color: white;
    font-family: var(--mavenpro);
    font-size: 15px;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
  }
  .rowField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .roomIds {
      margin-top: auto;
      margin-bottom: auto;
    }
    .priceType {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .priceValue {
    position: relative;
    width: 100%;
  }
  .updateBtn {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .closeupdatePrice {
      border: none;
      background-color: transparent;
      font-family: var(--mavenpro);
      font-size: 15px;
      font-weight: 500;
      height: 45px;
    }
    .updatePrice {
      border: none;
      background-image: var(--backgroundImg);
      font-family: var(--mavenpro);
      font-size: 15px;
      border-radius: 5px;
      width: 200px;
      height: 45px;
      color: white;
    }
  }
}
.folioActionWrapper {
  margin-top: auto;
  margin-bottom: auto;
  .folioActionBtns {
    background-color: #dddddd;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    margin-left: 15px;
    cursor: pointer;
    font-family: var(--mavenpro);
    border: none;
    margin-top: 1.1rem;
    margin-left: 35rem;
  }
}
.folioActions {
  background-color: transparent;
  font-family: var(--mavenpro);
  .item {
    margin-top: 10px;
    font-size: 15px;
    cursor: pointer;
  }
  .horizontalLine {
    border-top: 1px solid #dddddd;
    margin-top: 10px;
  }
  .item:hover {
    padding: 0px;
    background-color: #dddddd;
    margin-top: 10px;
    font-size: 15px;
    cursor: pointer;
  }
}

.Toastify__toast-container {
  color: white !important;
  width: fit-content;
  min-width: 300px;
}

.Toastify__toast {
  font-size: 14px !important;
}

/*Override toast style to sf green*/
.Toastify__toast--success {
  background-image: linear-gradient(-150deg, #1ab394, #1a91ae) !important;
}

/*Styling override for important toasts*/
.importantToast {
  font-size: 16px;
  width: 300px;
}

.important-error-toast-large {
  font-size: 16px;
  width: 400px !important;
  padding: 10px;
  line-height: normal;
}
