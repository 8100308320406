.allShopCardWrapper {
  height: 200px;
  cursor: pointer;
  overflow: visible;
  max-height: fit-content;
}
.allShopImgs {
  height: "100%";
  width: "175px";
  position: "relative";
  z-index: "100";
  border-radius: "5px 0px 0px 5px";
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .allShopCardWrapper {
    height: fit-content;
  }
  .allShopImgs {
    display: none;
    width: "75px";
    position: "relative";
    z-index: "100";
    border-radius: "5px 0px 0px 5px";
  }
}
