@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin";
// @import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin";

.agGridWrapr {
  // margin-top: 25px;
  font-family: var(--mavenpro);
  font-size: 25px;
  text-align: center;
  position: relative;
  width: 100%;
  .breadcrumbWrapper {
    margin-bottom: 25px;
    margin-left: 10px;
  }
  .agGridTableWrapper {
    position: relative;
    font-size: 15px;
    height: calc(100vh - 150px);
    .nameHeaderWrapper {
      display: grid;
      grid-template-columns: auto auto;
      margin-bottom: 35px;
      // margin-left: 35px;
      .headerOptions {
        cursor: pointer;
        align-self: left;
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        // .exportBtn{
        //     margin-left: 15px;
        //     background-color: white;
        //     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        //     border-radius: 8px;
        //     border: none;
        //     width: 45px;
        //     height: 35px;
        //     font-family: var(--mavenpro);
        //     cursor: pointer;
        //     padding-top: 7px;
        // }
        .paginationWrapper {
          margin-left: 15px;
          .paginationSelection {
            width: 75px;
            height: 45px;
            color: black;
            // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            background-color: white;
            border: none;
            border-radius: 5px;
            padding: 3px;
            font-family: var(--mavenpro);
            border: 1px solid #dddddd;
            font-size: 15px;
          }
          .paginationSelection:focus {
            outline: none;
          }
        }
        .columnVisibilityWrapper {
          margin-left: 15px;
          text-align: center;
          .columnSelector {
            width: 75px;
            height: 35px;
            color: black;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            background-color: white;
            border: none;
            border-radius: 5px;
            padding: 3px;
            font-family: var(--mavenpro);
          }
        }
        .searchFunctionality {
          .searchInp {
            width: 200px;
            height: 45px;
            color: black;
            // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            background-color: white;
            padding: 15px;
            border: 1px solid #dddddd;
            border-radius: 5px;
            font-family: var(--mavenpro);
            font-size: 15px;
          }
          .searchInp:focus {
            outline: none;
          }
        }
        .displayCalendarBtn {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          border: 1px solid #dddddd;
          height: 45px;
          border-radius: 5px;
          max-width: 250px;
          margin-left: 15px;
          background-color: transparent;
          .calIcon {
            margin-top: auto;
            margin-bottom: auto;
          }
          .calDate {
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 2.5px;
          }
        }
        .calendar {
          margin-left: 15px;
          height: 45px;
        }
        .dateFilterType {
          margin-left: 15px;
          .dateFilterWrapper {
            align-items: center;
            display: flex;
            flex-direction: row;
            background-color: white;
            color: black;
            // box-shadow: #0000003d 0px 3px 8px;
            max-width: 250px;
            height: 45px;
            border-radius: 5px;
            padding-left: 5px;
            font-size: 15px;
            border: 1px solid #dddddd;
          }
          .dateFilterWrapper:focus {
            outline: none;
          }
        }
        .exportWrapper {
          margin-left: 15px;
          .exportOptionsWrapper {
            height: 45px;
            width: 150px;
            border: 1px solid #dddddd;
            border-radius: 5px;
            // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            background-color: white;
            padding-left: 4.5px;
            color: black;
            font-family: var(--mavenpro);
            font-size: 15px;
          }
        }
        .downloadTypeBtn {
          height: 35px;
          width: fit-content;
          border: 1px solid #dddddd;
          border-radius: 5px;
          // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          background-color: white;
          padding-left: 4.5px;
          color: black;
          font-family: var(--mavenpro);
          font-size: 15px;
        }
      }
      .reportName {
        text-align: right;
        font-size: 18px;
        font-weight: 400;
        margin-top: auto;
        margin-right: 35px;
      }
    }

    .agTable {
      margin-top: 10px;
      margin-left: 5px;
      margin-right: 15px;
    }

    .ag-theme-material {
      margin-right: 25px;
      height: 100%;
      text-align: left;
      font-family: var(--mavenpro);
      width: 100%;
      font-size: 10px;
      position: absolute;
      border-radius: 5px;
      background-color: transparent;
      --ag-checkbox-checked-color: #1a91ae;
      --ag-checkbox-background-color: white;
      --ag-checkbox-indeterminate-color: grey;
      --ag-cell-horizontal-padding: 0;
      --ag-header-height: 15px;
      z-index: 0;
      @include ag-theme-material(
        (
          footer-height: 25px,
          font-size: 11px,
          font-family: var(--mavenpro),
        )
      );
    }

    .ag-theme-material .ag-header {
      height: 15px;
    }

    .summaryWrapper {
      position: relative;
      margin-top: 52x 0px;
      margin-bottom: 10px;
      text-align: left;
      margin-left: 165px;
      display: flex;
      flex-direction: column;
      .filtered {
        margin-top: 15px;
      }
      .selected {
        margin-top: 15px;
      }
    }
  }
  .pdfExportWrapper {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: var(--mavenpro);
  }
}

.customModal {
  border: none;
  border-radius: 8px;
  min-width: 400px;
  font-family: var(--mavenpro);
}

.customModalDeleteReport {
  border: none;
  border-radius: 8px;
  min-width: 25rem;
  min-height: 17rem;
  font-family: var(--mavenpro);
}

.expenseExportModal {
  border: none;
  border-radius: 8px;
  // min-width: 400px;
  font-family: var(--mavenpro);
}

.ifConfirmed {
  background-color: green;
  color: white;
}
.notConfirmed {
  background-color: red;
  color: white;
}

// is enquiry page
.enquiryCalBtn {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #dddddd;
  height: 35px;
  border-radius: 5px;
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: center;
  // max-width: 250px;
  margin-left: 15px;
  background-color: transparent;
  margin-top: auto;
  margin-bottom: auto;
  .calIcon {
    margin-top: auto;
    margin-bottom: auto;
  }
  .calDate {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
    font-size: 15px;
  }
}

// Export button
.exportBtns {
  height: 35px;
  width: fit-content;
  border: 1px solid #dddddd;
  border-radius: 5px;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  padding-left: 4.5px;
  color: black;
  font-family: var(--mavenpro);
  font-size: 15px;
}

.exportOption {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 15px;
}
.exportOption:hover {
  background-color: #dddddd;
}

// For group header cell
.ag-header-group-cell-with-group {
  font-weight: 700;
  font-size: 12px;
  line-height: 10em;
}
