.addServicedModalWrapper{
    font-family: var(--mavenpro);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    border-radius: 5px;
    .addInvoiceHeader{
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }
    .sep{
        position: relative;
        margin-top: 15px;
        width: 100%;
        border-top: 1px solid rgb(196, 196, 196);
    }
    .serviceDate{
        margin-top: 45px;
    }
    .serviceAmtType{
        margin-top: 25px;
    }
    .folioItemName{
        margin-top: 25px;
    }
    .applicableTax{
        margin-top: 25px;
    }
    .serviceAmount{
        margin-top: 25px;
    }
    .finalServiceAmount{
        margin-top: 25px;
    }
    .addServiceBtns{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        position: relative;
        margin-top: 45px;
        margin-bottom: 25px;
        .cancelBtn{
            float: left;
            border: none;
            background-color: transparent;
            text-decoration: underline;
            font-family: var(--mavenpro);
            font-size: 15px;
            cursor: pointer;  
            font-weight: 500;
        }
        .addBtn{
            float: right;
            border-radius: 5px;
            background-color: #24FAC0;
            background-image: var(--backgroundImg);
            font-family: var(--mavenpro);
            border: none;
            color: white;
            font-size: 14px;
            font-weight: 500;
            padding: 12px 20px 12px 20px;
        }
    }   
}

.addServicedModalWrapper::-webkit-scrollbar{
    display: none;
}