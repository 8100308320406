.singlelineloaders {
  height: 35px;
  width: 90%;
  animation-duration: 1.5s;
  // margin: 0.25rem;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: ease-in-out;
  background: #f6f7f81a;
  background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
  //   background-size: 1000px 104px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  //   background-color: white;
  margin-bottom: 5px;
}
