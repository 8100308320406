.startOrderWrapper{
    font-family: var(--mavenpro);
    font-size: 25px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: 100%;
    .productsWrapper{
        position: relative;
        overflow: hidden;
        width: 100%;
        align-items: center;
        .headers{
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            width: 100%;
            .headerShopName{
                font-size: 15px;
                font-weight: 400;
                background-color: transparent;
                margin-top: auto;
                margin-bottom: auto;
            }
            .subHeader{
                font-size: 15px;
                font-weight: 400;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 15px;
            }
        }
        .ordersWrapper{
            margin-top: 35px;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            align-items: center;
            // display: grid;
            // grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        }
        .startOrderButton{
            margin-top: 25px;
            .startOrderBtn{
                background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
                color: white;
                padding: 20px;
                border: none;
                border-radius: 12px;
                font-family: var(--mavenpro);
                font-size: 17.5px;
                width: 150px;
                margin-right: 25px;
                margin-top: 45px;
            }
        }
    }
    .cartWrapper{
        // height: 450px;
        display: none;
        position: relative;
        overflow-x: hidden;
        margin-top: 50px;
        color: white;
        margin-left: 15px;
        overflow-y: scroll;
        width: 25%;
        // height: 75%;
        height: fit-content;
        margin-right: 15px;
        // align-content: center;
        padding-bottom: 100px;
        .yourOrdersWrapper{
            position: fixed;
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            font-family: var(--mavenpro);
            height: fit-content;
            width: 25%;
            padding: 15px;
            border: none;
            border-radius: 15px;
            margin-bottom: 25px;
            background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
            overflow-y: scroll;
            padding-bottom: 100px;
            .yourOrdersHeading{
                font-size: 25px;
                // color: black;
                margin: 15px;
            }
            .productsAddedWrapper{
                // border: 0.5px solid black;
                // background-color: #F9F9F9;
                background-color: transparent;
                border-radius: 25px;
                font-size: 17px;
                display: flex;
                flex-direction: column;
                padding: 17.5px;
                margin-top: 25px;
                position: relative;
                .prodsWrapper{
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    .prodImage{
                        width: 65px;
                        height: 65px;
                        border-radius: 8px;
                        overflow: hidden;
                        .prodImg{
                            width: 65px;
                            height: 65px;
                        }
                    }
                    .prodInfoWrapper{
                        margin-top: auto;
                        margin-bottom: auto;
                        // margin-left: 25px;
                        width: fit-content;
                        display: flex;
                        flex-direction: column;
                        .prodName{
                            font-size: 15px;
                        }
                        .prodPrice{
                            margin-top: 12px;
                            font-size: 12.5px;
                        }
                    }
                    .prodTotalQtyInfoWrapper{
                        margin-top: auto;
                        margin-bottom: auto;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        // margin-left: 75%;
                        margin-left: auto;
                        margin-right: 0;
                        // margin-left: 125px;
                        .prodTotalQtyWrapper{
                            background-color: transparent;
                            border: 0.5px solid white;
                            border-radius: 5px;
                            font-family: var(--mavenpro);
                            font-size: 12.5px;
                            padding: 2.5px;
                            display: flex;
                            flex-direction: row;
                            .decrQty{
                                margin-top: auto;
                                margin-bottom: auto;
                                font-family: var(--mavenpro);
                                border: none;
                                background-color: transparent;
                                color: white;
                            }
                            .totalQty{
                                margin-left: 5px;
                                margin-top: auto;
                                margin-bottom: auto;
                            }
                            .incrQty{
                                margin-left: 5px;
                                margin-top: auto;
                                margin-bottom: auto;
                                color: white;
                                border: none;
                                background-color: transparent;
                            }
                        }
                    }
                    .totalProdPrice{
                        font-size: 12.5px;
                        // color: black;
                        margin-top: 5px;
                    }
                }
            }
            .productsAddedWrapper::-webkit-scrollbar{
                display: none;
            }
            .horizontalSeparator{
                width: 100%;
                color: white;
                border-top: 0.5px solid white;
                margin-top: 50px;
            }
            .pricesSummaryWrapper{
                // background-color: transparent;
                border-radius: 25px;
                font-size: 17px;
                display: flex;
                flex-direction: column;
                padding: 17.5px;
                width: 100%;
                margin-top: 25px;
                .subTotalWrapper{
                    font-size: 15px;
                    display: grid;
                    grid-template-columns: auto auto;
                }
                .taxesWrapper{
                    margin-top: 15px;
                    font-size: 15px;
                    display: grid;
                    grid-template-columns: auto auto;
                }
                .totalPriceWrapper{
                    margin-top: 15px;
                    font-size: 15px;
                    display: grid;
                    grid-template-columns: auto auto;
                    font-weight: 500;
                }
            }
            .placeOrderButton{
                bottom: 0;
                margin-bottom: 25px;
                overflow: hidden;
                position: absolute;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                width: 75%;
                .placeOrderBtn{
                    font-family: var(--mavenpro);
                    background-color: white;

                    padding: 12px;
                    // color: black;
                    // position: fixed;
                    border: none;
                    font-size: 18px;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    border-radius: 8px;
                    width: 100%;
                }
            }
        }
    }
    .cartWrapper::-webkit-scrollbar{
        display: none;
    }
    .yourOrdersWrapper::-webkit-scrollbar{
        display: none;
    }
}


// page in case there are no products
.redirectToAddProducts{
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    align-items: center;
    text-align: center;
    .noProdImg{
        height: 500px;
        width: auto;
    }
    .addProducts{
        margin-top: 45px;
        border: none;
        background-image: var(--backgroundImg);
        padding: 5px;
        width: 125px;
        height: 45px;
        font-family: var(--mavenpro);
        font-size: 15px;
        font-weight: 500;
        color: white;
        border-radius: 5px;
    }
}

// start order button
.startOrderBtnWrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    .startOrderBtn{
        border: none;
        background-image: var(--backgroundImg);
        font-family: var(--mavenpro);
        font-size: 17.5px;
        color: white;
        background-color: transparent;
        width: 150px;
        height: 50px;
        border-radius: 5px;
        margin-right: 45px;
    }
}
// search wrapper
.searchAddWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: 25px;
    .searchBar{
        // background-color: white;
        // border: 1px solid black;
        // position: absolute;
        border: none;
        border-radius: 45px;
        width: 800px;
        height: 60.5px;
        color: black;
        font-size: 15px;
        padding-left: 25px;
        font-family: var(--mavenpro);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }
    .searchBar::placeholder{
        margin-left: 45px;
        font-size: 15px;
        font-family: var(--mavenpro);
        // color: rgb(139, 135, 135);
        // color: black;;
    }
    .searchBar:focus{
        outline: none;
    }
    .separator1{
        margin-left: -125px;
        height: 40px;
        border-left: 0.75px solid black;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
    }
    .applyFilters{
        margin-left: 15px;
        color: black;
        .applyFiltersBtn{
            border: none;
            font-family: var(--mavenpro);
            font-size: 17.5px;
            color: black;
            height: 65px;
            background-color:transparent;
            text-decoration: underline;
            font-weight: 500;
        }
    }
}


// start order modal
.startOrderModal{
    width: 400px;
    font-family: var(--mavenpro);
    border-radius: 12px;
    overflow: none;
    .startOrderModalWrapper{
        margin-top: 7px;
        font-size:15px;
        align-items: center;
        justify-content: center;
        position: relaitve;
        width: 100%;
        .startOrderHeader{
            text-align: center;
            background-color: transparent;
            border-bottom: 1px solid #DDDDDD;
        }
        .startOrderContent{
            padding: 10px;
            position: relative;
            width: 100%;
            align-items: center;
            justify-content: center;
            .startOrderFields{
                margin-top: 20px;
            }
            .startOrderSelectFields{
                // align-items: center;
                position: relative;
                margin-top: 20px;
                width: 100%;
                .formLabel{
                    text-align: center;
                }
            }
            .startOrderBtnWrapper{
                margin-top: 35px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .cancel{
                    border: none;
                    text-decoration: underline;
                    font-family: var(--mavenpro);
                    font-size: 15px;
                    font-weight: 500;
                    color: black;
                    background-color: transparent;
                }
                .startOrderBtn{
                    border: none;
                    background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
                    font-family: var(--mavenpro);
                    font-size: 15px;
                    font-weight: 500;
                    padding: 8px;
                    border-radius: 5px;
                    color: white;
                }
            }
        }
    }
}
