.dashboardWrapper {
  margin-top: 25px;
  width: 72%;
  margin-left: 1%;
  margin-right: 3%;
  // align-items: center;
  display: flex;
  flex-direction: column;
  font-family: var(--mavenpro);
  .filterWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    .greeting {
      margin-top: auto;
      color: #222;
      font-weight: 500;
      font-size: 35px;
      display: flex;
      flex-direction: column;
      font-family: var(--mavenpro);
      .msg {
        // border: none;
        font-family: var(--mavenpro);
        font-size: 18px;
        font-weight: 100;
        background-color: transparent;
        color: #222;
        width: 35ch;
      }
    }
    .otherWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: auto;
      margin-bottom: auto;
      .searchBar {
        margin-top: auto;
        margin-bottom: auto;
      }
      .CTAs {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
  .bookingInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: scroll;
    // justify-content: flex-start;
    margin-top: 0px;
    background-color: transparent;
    width: 100%;
    padding: 25px 30px 30px;
    position: relative;
    margin-left: -30px;
    .infoCard {
      position: relative;
      width: 100%;
      margin-top: 10px;
      margin-right: 15px;
      .informationCardWrapper {
        transition: transform 200ms ease-in-out 0s;
        transform: scale(0.9);
      }
    }
  }
  .bookingInfo::-webkit-scrollbar {
    display: none;
  }
  .bookingDetails {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    .bookingHeader {
      font-size: 17.5px;
      margin-bottom: 15px;
    }
    .noBookingData {
      width: 475px;
      height: auto;
      align-self: center;
    }
    .helperText {
      text-align: center;
      position: relative;
      width: 100%;
      font-size: 15px;
      font-family: var(--mavenpro);
      margin-bottom: 25px;
    }
  }
}

// dashboard card

.dashboardCardWrapper {
  position: relative;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  background-color: white;
  margin-top: 15px;
  .dashboardContentWrapper {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .customerDetailsWrapper {
    .personalDetails {
      .name {
        font-weight: 500;
      }
      .phone {
        font-size: 12.5px;
      }
    }
    .personalBookingDetails {
      .bookingId {
        text-decoration: underline;
      }
      .bookingPeriod {
        font-size: 12.5px;
      }
    }
  }
}
.bookingSummaryWrapper {
  align-items: flex-end;
  align-self: right;
  .bookingKey {
    text-align: right;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    font-size: 15px;
    justify-content: flex-end;
    .pendingCheckout {
      background-color: rgb(214, 40, 40);
      color: white;
      font-size: 13px;
      border-radius: 25px;
      width: fit-content;
      margin-right: 10px;
      margin-top: auto;
      margin-bottom: auto;
      padding: 5px;
    }

    .pendingArrivals {
      background-color: rgb(214, 40, 40);
      color: white;
      font-size: 13px;
      border-radius: 25px;
      width: fit-content;
      margin-right: 10px;
      margin-top: auto;
      margin-bottom: auto;
      padding: 5px;
    }
  }
  .detailsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .bookingSource {
      font-weight: 500;
    }
    .bookingStatues {
      // text-transform: uppercase;
      font-size: 14px;
    }
    .bookingAmt {
      font-weight: 500;
      font-size: 14px;
    }
  }
}

// second card wrapper
.subCardWrapper {
  position: relative;
  width: 100%;
  display: flex;
  .subCardItems {
    position: relative;
    width: 100%;
    justify-content: flex-end;
    display: flex;
    .outline {
      border: 1px solid black;
      font-family: var(--mavenpro);
      font-size: 14px;
      text-transform: capitalize;
      background-color: white;
      color: black;
    }
    .contained {
      color: white;
      font-family: var(--mavenpro);
      text-transform: capitalize;
      background-image: var(--backgroundImg);
      font-size: 14px;
    }
  }
}

.customizeDatePicker {
  .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    font-weight: bold;
  }
  .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    margin-left: -30px;
  }
  .css-mnn31 {
    font-weight: bold;
  }
  .css-1nvf7g0 {
    margin-left: -30px;
  }
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (9 / 16 = 0.5625) */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blinking-button {
  animation: blink 1s infinite;
}