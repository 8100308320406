.hkCTableWrapper{
    font-family: var(--mavenpro);
    color: black;
    margin-top: 45px;
    font-size: 14px;
    border: none;
    .actions{
        display: flex;
        flex-direction: row;
        margin-bottom: 35px;
        position: relative;
        .hkSearchBarWrapper{
            // margin-bottom: 25px;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 25px;
            .hkSearchBar{
                display: var(--mavenpro);
                border: 2px solid #E0E0E0;
            }
        }
        .otherActionsWrapper{
            position: relative;
            margin-top: -2px;
            display: flex;
            flex-direction: row;
            width: 100%;
            .calendar{
                font-size: 15px;
                font-weight: 500;
                color: black;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 25px;
            }
            .moreFilters{
                margin-left: 25px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
    .foo{
        background-color: #EFEFEF;
        font-size: 18px;
        font-family: var(--mavenpro);
        height: 25px;
    }
    .tableBody{
        border-color: transparent;
        padding: 12px;
        font-family: var(--mavenpro);
    }
}

.customOOOModal{
    padding: 15px;
    position: relative;
    width: 400px;
    .bulkMarkOOOWrapper{
        position: relative;
        width: 100%;
        .oooHeader{
            text-align: center;
            margin-bottom: 25px;
            margin-top: 15px;
            font-size: 14.5px;
        }
        .separator{ 
            position: relative;
            width: 100%;
            border: 1px solid rgb(211, 210, 210);
        }
        .selectedRoomIds{
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            margin-top: 35px;
            align-items: center;
            justify-content: center;
        }
        .inpWrapper{
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            align-items: center;
            justify-content: center;
            .dateRange{
                margin-top: 25px;
            }
            .reasonWrapper{
                margin-top: 25px;
                position: relative;
                width: 100%;
            }
        }
        .oooButtonWrapper{
            margin-top: 25px;
            border-top: 1px solid #dddddd;
            padding-top: 25px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            width: 100%;
            .cancelBtn{
                border: none;
                background-color: transparent;
                font-family: var(--mavenpro);
                font-size: 12.5px;
                cursor: pointer;    
                margin-top: auto;
                margin-bottom: auto;
                font-weight: 500;
            }
            .submitBtn{
                background-color: black;
                color: white;
                font-family: var(--mavenpro);
                font-size: 14px;
                width: 100px;
                height: 45px;
                // margin-right: 35px;
                border-radius: 5px;
                text-align: center;
                cursor: pointer;    
                margin-top: auto;
                margin-bottom: auto;
                font-weight: 400;
            }
        }
    }
}
