.modal {
  height: 100%;
  flex-direction: row-reverse;
  animation: openModal 0.2s ease-in;
}
.myModalBody6533 {
  margin: 0 0 0 auto !important;
  height: 100vh !important;
  max-width: 30vw !important;
}
.myModalContent32323 {
  border-radius: 0;
  height: 100vh;
  overflow-y: auto;
}

.supportModalButton {
  width: 100%;
  color: #36454f !important;
  border-color: #36454f !important;
  font-weight: 650 !important;
}
.newsupportModalButton {
  color: #36454f !important;
  border-color: #36454f !important;
  font-weight: 650 !important;
}
/* #1BC5BD */
.supportModalMeetButton {
  color: #009e96 !important;
  border-color: #009e96 !important;
  font-weight: 650 !important;
}

.supportModalLink {
  font-weight: 650;
  color: #009e96 !important;
}
@keyframes openModal {
  0% {
    transform: translate(40vw, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
