.custInformationWrapper{
    font-family: var(--mavenpro);
    position: relative;
    height: 100%;
    width: 700px;
    overflow: hidden;
    font-size: 15px;
    .cutomerInfoHeader{
        margin-top: 15px;
        font-weight: 500;
        margin-bottom: 15px;
        font-size: 20px;
    }
    .customerInfoForm{
        // display: grid;
        // grid-template-columns: auto auto auto;
        // margin-top: 15px;
        // position: relative;
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        .customerInfo{
            display: flex;
            flex-direction: column;
            margin-top: 25px;
            margin-bottom: 25px;
            .custInfoLabel{
                font-family: var(--mavenpro);
                font-size: 14.5px;
                color: black;
                align-self: flex-start;
                // margin-left: 35px;
            }
            .custInfoInput{
                border: 1px solid rgb(204, 204, 204);
                background-color: transparent;
                width: 545px;
                padding: 12px;
                height: 55px;
                border-radius: 5px;
                font-family: var(--mavenpro);
                font-size: 14.5px;
            }
            .custInfoInput:focus{
                outline: none;
            }
        }
    }
    .customerBtnWrapper{
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        width: 100%;
        margin-top: 25px;
        .customerBtns{
            // display: flex;
            // flex-direction: row;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;
            width: 100%;
            .cancelBtn{
                // justify-content: flex-start;
                float: left;
                margin-top: auto;
                margin-bottom: auto;
                border: none;
                background-color: transparent;
                text-decoration: underline;
                font-size: 15px;
                font-weight: 500;
            }
            .saveCustomer{
                // justify-content: flex-end;
                float: right;
                margin-top: auto;
                margin-bottom: auto;
                background-image: var(--backgroundImg);
                font-family: var(--mavenpro);
                color: white;
                border: none;
                border-radius: 5px;
                font-size: 15px;
                height: 45px;
                padding: 5px;
                width: 125px;
            }
        }
    }
}