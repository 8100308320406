// side drawer to edit the shops
.editShopSideDrawer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 550px;
    font-family: var(--mavenpro);
    justify-content: center;
    text-align: center;
    position: relative;
    .header{
        font-size: 15px;
        text-align: center;
        font-weight: 500;
        border-bottom: 1px solid #DDDDDD;
        margin-top: 15px;
        background-color: transparent;
        position: relative;
        width: 100%;
        justify-content: center;
    }
    .editInfoWrapper{
        margin-top: 25px;
        position: relative;
        width: 100%;
        // align-items: center;
        .editField{
            margin-top: 35px;
        }
        .editFormField{
            position: relative;
            width: 550px;
            margin-top: 35px;
            align-items: center;
            .editFormLabel{
                text-align: left;
                margin-left: 45px;
            }
        }
    }
    .btnWrapper{
        margin-top: 55px;
        margin-bottom: 25px;
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top:25px;
        border-top: 1px solid #DDDDDD;
        .cancel{
            border: none;
            background-color: transparent;
            font-family: var(--mavenpro);
            font-size: 15px;
            font-weight: 500;
            text-decoration: underline;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 25px;
        }
        .update{
            font-weight: 500;
            font-family: var(--mavenpro);
            font-size: 15px;
            width: 125px;
            height: 45px;
            color: white;
            background-image: var(--backgroundImg);
            margin-top: auto;
            margin-bottom: auto;
            border: none;
            border-radius: 7px;
            margin-right: 25px;
        }
    }

}