// All shops wrapper
.addShopWrapper {
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: calc(100vh - 165px);
  }
  .image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: cover;
    height: calc(100vh - 25px);
    // border-radius: 25px;
  }
}

//   @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
//     .addShopWrapper {
//       margin-left: 0vh;
//       margin-right: 0vh;
//     }
//   }

// background color for chip label
.chipLabel {
  color: white;
  background-image: var(--backgroundImg);
  border-radius: 5;
  width: fit-content;
}
