.startOrderTemplateWrapper{
    font-family: var(--mavenpro);
    margin-top: 25px;
    margin-bottom: 10px;
    margin-right: 25px;
    font-size: 25px;
    border: none;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    // padding: 25px;
    overflow: hidden;
    width: 325px;
    // position: relative;
    .imageWrapper{
        width: 100%;
        height: 200px;
        border: none;
        border-radius: 15px 15px 0px 0px;
        overflow: hidden;
        // z-index: -100;
        position: relative;
        .productImg{
            width: 100%;
            height: 200px;
        }
        .productIcon{
            position: absolute;
            top:0;
            right:0;
            left:0;
            height: 200px;
            color: white;
            font-size: 45px;
            font-weight: 500;
            font-family: var(--mavenpro);
            text-align: center;
            padding-top: 75px;
            background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
        }
    }
    .selfServiceEnabled{
        position: absolute;
        padding: 2px;
        font-size: 12px;
        color: black;
        z-index: 100;
        background-color: white;
        border-radius: 12px;
        margin-top: -45px;
        margin-left: 200px;
        font-family: var(--mavenpro);
    }
    .selfServiceEnabledWithoutImage{
        padding: 15px;
        font-size: 15px;
        color: white;
        background-color: black;
        border: none;
        border-radius: 8px;
        font-family: var(--mavenpro);
    }
    .informationWrapper{
        display: flex;
        flex-direction: column;
        padding: 15px;
        // flex-direction: row;
        .basicProdInfo{
            display: flex;
            flex-direction: row;
            margin-top: 15px;
            .prodName{
                font-size: 17.5px;
                font-weight: 500;
                color: black;
                margin-top: auto;
                margin-bottom: auto;
            }
            .category{
                font-size: 15px;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 12px;
            }
        }
        .priceWrapper{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin-top: 8px;
            .selfServicePrice{
                display: flex;
                flex-direction: row;
                .priceList{
                    font-size: 13.5px;
                    color: black;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 8px;
                }
            }
            .price{
                font-size: 13.5px;
                color: black;
                margin-top: auto;
                margin-bottom: auto;
            }
            .qtyLeft{
                color: red;
                margin-top: 8px;
                font-size: 13.5px;
            }
        }
    }
    .prodQtyButton{
        padding: 15px;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        // flex-direction: row;
        // justify-content: space-between;
        .callToAction{
            display: grid;
            grid-template-columns: 40% 60%;
            .addToCartQty{
                display: grid;
                grid-template-columns: auto auto auto;
                background-color: transparent;
                border: 1px solid #dddddd;
                margin-right: 7px;
                border-radius: 5px;
                .incrementBtn{
                    margin-top: auto;
                    margin-bottom: auto;
                    border: none;
                    background-color: transparent;
                    font-family: var(--mavenpro);
                    font-size: 25px;
                    font-weight: 500;
                }
                .decrementBtn{
                    margin-top: auto;
                    margin-bottom: auto;
                    border: none;
                    background-color: transparent;
                    font-family: var(--mavenpro);
                    font-size: 25px;
                    font-weight: 500;
                }
            }
        }
        .qty{
            border: none;
            background-color: transparent;
            position: relative;
            font-size: 15px;
            width: 100%;
            text-align: center;
            margin-top: auto;
            margin-bottom: auto;
        }
        .qty:focus{
            outline:none;
        }
        .qty::-webkit-outer-spin-button,
        .qty::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        /* Firefox */
        .qty[type=number] {
        -moz-appearance: textfield;
        }
        .cartIconButton{
            border: none;
            border-radius: 5px;
            background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
            padding-top: 8px;
            text-align: center;
            padding-bottom: 8px;
            align-items: center;
            margin-top: 25px;
            position: relative;
            width: 100%;
            color: white;
            font-size: 15px;
        }
    }
}


