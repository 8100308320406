.cardscss {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.cardes {
  background: #fff;
  width: 26em;
  border-radius: 0.6em;
  /* margin: 1em; */
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
}

.cardes:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
    0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
    0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.cardes h2 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.3em;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  transition: all ease-in 100ms;
}

.cardes p {
  color: #777;
}

.card-bodys {
  padding: 1.2em;
  height: 12rem;
}
