.invoiceSummary {
  font-family: var(--mavenpro);
  font-size: 15px;
  position: relative;
  width: 100%;
  height: fit-content;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: -25px;
  .summaryHeader {
    text-align: center;
    border-bottom: 1px solid #dddddd;
    font-weight: 500;
    padding: 25px;
    background-image: var(--backgroundImg);
    color: white;
    position: relative;
    width: 100%;
  }
  .invoiceContent {
    position: relative;
    padding: 7.5px;
    width: 100%;
    height: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .feeBreakdownWrapper {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      .feeBreakdownHeader {
        position: relative;
        width: 100%;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        text-decoration: underline;
      }
      .feeBreakdown {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 8px;
        padding: 5px;
        .breakdownHeader {
          position: relative;
          width: 60%;
          display: flex;
          flex-wrap: wrap;
          word-wrap: break-word;
        }
        .breakdownVal {
          position: relative;
          width: 40%;
          display: flex;
          flex-wrap: wrap;
          word-wrap: break-word;
          justify-content: flex-end;
          // align-items: right;
        }
      }
    }
    .separator {
      margin-top: 10px;
      margin-bottom: 10px;
      border-top: 1px solid #dddddd;
    }
    .taxesBreakdownWrapper {
      text-align: center;
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      // padding: 5px;
      .taxBreakdownHeader {
        font-weight: 500;
        font-family: var(--mavenpro);
        font-size: 15px;
        text-decoration: underline;
      }
      .taxBreakdown {
        position: inherit;
        width: 100%;
        .breakdowns {
          position: inherit;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 10px;
          padding: 5px;
        }
      }
    }
    .paymentBreakdownHeader {
      position: relative;
      text-align: center;
      // margin-bottom: 10px;
      font-size: 15px;
      font-weight: 500;
      text-decoration: underline;
    }
  }
  .invoiceRenderBtns {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 20px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    z-index: 100;
    border-top: 1px solid #dddddd;
    margin-bottom: 15px;
    .settleDuesBtn {
      border-radius: 5px;
      font-family: var(--mavenpro);
      font-size: 15px;
      border: none;
      background-color: black;
      color: white;
      height: 45px;
      width: 135px;
      position: relative;
    }
    .bookStatusBtn {
      border: none;
      background-image: var(--backgroundImg);
      font-family: var(--mavenpro);
      font-size: 15px;
      color: white;
      height: 45px;
      width: 135px;
      border-radius: 5px;
      position: relative;
    }
  }
  .invoiceRenderBtn {
    position: relative;
    width: 100%;
    padding-top: 20px;
    z-index: 100;
    justify-content: center;
    margin-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dddddd;
    .settleDuesBtnLarge {
      position: relative;
      border-radius: 5px;
      font-family: var(--mavenpro);
      font-size: 15px;
      border: none;
      background-image: var(--backgroundImg);
      height: 45px;
      width: 100%;
      align-self: center;
      color: white;
    }
  }
}
// rendering settle dues options

// .invoiceContent::-webkit-scrollbar{
//     display: none;
// }

.settleDuesActions {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  font-family: var(--mavenpro);
  .item {
    background-color: transparent;
    margin-top: 10px;
    cursor: pointer;
  }
  .item:hover {
    background-color: #dddddd;
    margin-top: 10px;
    cursor: pointer;
  }
  .horizontalSep {
    border-top: 1px solid #dddddd;
    margin-top: 10px;
  }
}

// settle dues modal

.settleDuesModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: none;
  box-shadow: 24;
  font-family: var(--mavenpro);
  .settleDuesWrapper {
    display: flex;
    flex-direction: column;
    .settleDuesHeader {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .settleDuesHeading {
        background-color: transparent;
        font-size: 15px;
        .roomIdHeader {
          display: inline-grid;
          margin-left: 2rem;
          width: 100px;
        }
      }
      .close {
        font-family: var(--mavenpro);
        font-size: 25px;
        font-weight: 500;
        cursor: pointer;
      }
    }
    .amountWrapper {
      display: flex;
      flex-direction: column;
      .fieldWrapper {
        margin-top: 25px;
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        opacity: 0;
      }
    }
  }
  .settleDuesSubmitBtns {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 15px;
    .cancelBtn {
      font-size: 15px;
      font-weight: 500;
      font-family: var(--mavenpro);
      border: none;
      background-color: transparent;
    }
    .submit {
      font-size: 15px;
      font-family: var(--mavenpro);
      border: none;
      background-image: var(--backgroundImg);
      height: 42.5px;
      width: 95px;
      border-radius: 5px;
      color: white;
    }
    :disabled {
      background-color: lightgrey;
      color: #222;
      font-weight: 500;
      background-image: none;
    }
  }
}

// cash deposit
.cashDeposit {
  margin-top: 15px;
  margin-left: 15px;
  width: 100%;
  height: fit-content;
  font-family: var(--mavenpro);
  font-size: 15px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  padding: 15px;
  .cashDepositHeader {
    margin-top: 5px;
    font-weight: 500;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 10px;
  }
  .cashDepositVal {
    margin-top: 10px;
  }
}

//card styles

#card {
  height: 200px;
  width: 100%;
  max-width: 385px;
  position: relative;
  // top: -100px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-position: center;
  color: #eee;
  filter: drop-shadow(1px 3px 10px #222);

  animation: fadeIn 0.2s linear 1;
}

@keyframes fadeIn {
  from {
    top: -150px;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#card .card_header {
  display: flex;
  justify-content: space-between;
  height: 70px;
}

#card .card_sticker {
  width: 50px;
  height: 40px;
  background-image: url("https://images.pexels.com/photos/728471/pexels-photo-728471.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500");
  background-position: center;
  background-size: cover;
  object-fit: contain;
  border-radius: 5px;
}

#card .card_logo {
  max-width: 60px;
}

#card .card_body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60%;
  letter-spacing: 4.5px;
}

#card .card_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 75px;
}

.settleDuesBtnForModal {
  border-radius: 5px;
  font-family: var(--mavenpro);
  font-size: 15px;
  border: none;
  background-color: black;
  color: white;
  height: 33px;
  width: 115px;
  position: relative;
}

.settleDuesBtnForModals {
  border-radius: 5px;
  font-family: var(--mavenpro);
  font-size: 15px;
  border: none;
  background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
  color: white;
  font-weight: "500";
  height: 2.5rem;
  width: 115px;
  position: relative;
  margin-left: 1rem;
}

.viewManageToggleBtns {
  .submit {
    border-radius: 0.5rem;
  }
  .activeBtn {
    background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
    color: #fff !important;
    border: 2px solid white;
  }
  .inactiveBtn {
    background-color: white;
    color: #222 !important;
    transform: scale(0.9);
    border: 2px solid #222222;
  }
}

.viewManageCTABtns {
  border-radius: 0.5rem;
  background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
  color: #fff !important;
  min-width: 6rem;
  border: 2px solid white;
}
