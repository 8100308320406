.shimmer-loader-wrapper {
  position: relative;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: ease-in-out;
  background: #f6f7f81a;
  background: linear-gradient(to right, #eeeeee 4%, #dddddd 8%, #eeeeee 16%);
  overflow: hidden;
  //   background-color: white;
}
