.reportWrapper{
    font-family: var(--mavenpro);
    background-color: transparent;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding-left: 2.5%;
    padding-right: 2.5%;
    .searchWrapper{
        position: relative;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-bottom: 15px;
    }
    .reportHeading{
        font-weight: 500;
        font-size: 22px;
        margin-bottom: 15px;
        border: none;
        text-align: left;
        position: relative;
        width: 100%;
    }
    .allReportWrapper{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 25px;
        overflow: hidden;
        background-color: white;
    }
}