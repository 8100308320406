.table-fixed thead {
    width: 100%;
  }
.table-fixed tbody {
    height: 173px;
    overflow-y: auto;
    width: 100%;
  }
  .table-fixed thead, .table-fixed tbody {
    display: block;
  }
  .table-fixed tbody td, .table-fixed thead > tr> th {
    /* float: left; */
    /* border-bottom-width: 0; */
  }