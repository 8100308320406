.advancePortfolioWrapper{
    font-family: var(--mavenpro);
    display: flex;
    flex-direction: column;
    position: relative;
    .advFolioActionsWrapper{    
        width:100%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .folioPage{
            margin-top: auto;
            margin-bottom: auto;
        }
        .advFolioActionButton{
            border: none;
            background-color: white;
            font-family: var(--mavenpro);
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            font-size: 14.5px;
            font-weight: 500;
            border-radius: 12px;
            padding: 12px;
            margin-top: auto;
            margin-bottom: auto;;
        }
        .advFolioItems{
            margin-top: 17.5px;
            display: flex;
            flex-direction: column;
            font-size: 13.5px;
            font-weight: 500;
            .advFolioItem{
                margin-bottom: 12px;
            }
        }
    }
    .advancedFolioReport{
        margin-top: 45px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
        background-color: white;
        border: none;
        border-radius: 12px;
        padding: 0px;
        .folioId{
            border-radius: 12px 12px 0px 0px;
            border: none;
            color: white;
            background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
            font-size: 17.5px;
            padding: 15px; 
        }
        .hotelCustInfoWrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            position: relative;
            padding: 12px;
            margin-top: 25px;
            .hotelInfo{
                width: 49%;
                display: flex;
                flex-direction: column;
                font-size: 14px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                padding: 17.5px;
                border-radius: 12px;
                background-color: white;
                .hotelName{
                    font-weight: 500;
                    text-align: center;
                    position: relative;
                    font-size: 15px;
                    // margin-top: 15px;
                }
                .sep{
                    position: relative;
                    width: 100%;
                    border-top: 1px solid rgb(214, 214, 214);
                    margin-top: 10px;
                }
                .hotelAdd{
                    margin-top: 20px;
                    margin-left: 25px;
                }
                .hotelEmail{
                    margin-top: 15px;
                    margin-left: 25px;
                }
                .hotelContact{
                    margin-top: 15px;
                    margin-left: 25px;
                }
            }
            .customerInfo{
                width: 49%;
                display: flex;
                flex-direction: column;
                font-size: 14px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                padding: 17.5px;
                border-radius: 12px;
                background-color: white;
                .custName{
                    font-weight: 500;
                    text-align: center;
                    position: relative;
                    font-size: 15px;
                    // margin-top: 15px;
                }
                .sep{
                    position: relative;
                    width: 100%;
                    border-top: 1px solid rgb(214, 214, 214);
                    margin-top: 10px;
                }
                .custAdd{
                    margin-top: 20px;
                    margin-left: 25px;
                }
                .custEmail{
                    margin-top: 15px;
                    margin-left: 25px;
                }
                .custContact{
                    margin-top: 15px;
                    margin-left: 25px;
                }
                .dates{
                    margin-top: 15px;
                    margin-left: 25px;
                    display: flex;
                    flex-direction: row;
                    // justify-content: space-evenly;
                    .checkIn{
                        margin-top: auto;
                        margin-bottom: auto;
                        display: flex;
                        flex-direction: column;
                        .headerDates{
                            font-size: 12.5px;
                            font-weight: 500;
                        }
                        .date{
                            margin-top: 5.5px;
                        }
                    }
                    .checkOut{
                        margin-left: 45px;
                        margin-top: auto;
                        margin-bottom: auto;
                        display: flex;
                        flex-direction: column;
                        .headerDates{
                            font-size: 12.5px;
                            font-weight: 500;
                        }
                        .date{
                            margin-top: 5.5px;
                        }
                    }
                }
                .travelInfo{
                    margin-top: 15px;
                    margin-left: 25px;
                    display: flex;
                    flex-direction: row;
                    // justify-content: space-evenly;
                    .nights{
                        margin-top: auto;
                        margin-bottom: auto;
                        display: flex;
                        flex-direction: column;
                        .headerNight{
                            font-size: 12.5px;
                            font-weight: 500;
                        }
                        .val{
                            margin-top: 5.5px;
                        }
                    }
                    .adults{
                        margin-left: 45px;
                        margin-top: auto;
                        margin-bottom: auto;
                        display: flex;
                        flex-direction: column;
                        .headerAdults{
                            font-size: 12.5px;
                            font-weight: 500;
                        }
                        .val{
                            margin-top: 5.5px;
                        }
                    }
                    .children{
                        margin-left: 45px;
                        margin-top: auto;
                        margin-bottom: auto;
                        display: flex;
                        flex-direction: column;
                        .headerChildren{
                            font-size: 12.5px;
                            font-weight: 500;
                        }
                        .val{
                            margin-top: 5.5px;
                        }
                    }
                }
                .roomAlloted{
                    margin-top: 15px;
                    margin-left: 25px;
                    display: flex;
                    flex-direction: column;
                    .headerRoom{
                        font-size: 12.5px;
                        font-weight: 500;
                    }
                    .ids{
                        margin-top: 5.5px;
                    }
                }
            }
        }
        .advFolioTable{
            margin: 35px;
            margin-top: 45px;
        }
    }
}