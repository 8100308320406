.invoiceSummTableWrapper{
    position: relative;
    width: 100%;
    // padding: 10px;
    font-family: var(--mavenpro);
    font-size: 15px;
    .filtersWrapper{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .searchWrapper{
            margin-top: auto;
            margin-bottom: auto;
        }
        .removeServiceBtn{
            position: relative;
            border: none;
            color: white;
            font-family: var(--mavenpro);
            font-size: 15px;
            height: 45px;
            border-radius: 5px;
            width: 115px;
            background-color: black;
            font-weight: 400;
            float:right;
        }
    }
    .folioTableWrapper{
        margin-top: 15px;
    }
}

