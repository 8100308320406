.cartInvoiceWrapper{
    font-family: var(--mavenpro);
    font-size: 15px;
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
    // position: relative;
    .cartInvoiceBreadCrumb{
        font-size: 17px;
    }
    .invoicePrintWrapper{
        position: relative;
        width: 100%;
        align-items: right;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        // margin-top: -15px;
        .completeOrderBtn{
            width: 150px;
            height: 45px;
            font-family: var(--mavenpro);
            font-size: 15px;
            border: none;
            border-radius: 5px;
            color: white;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
        }
        .printInvoiceOptionsWrapper{
            position: relative;
            .openOptionsBtn{
                font-family: var(--mavenpro);
                display: flex;
                flex-direction: column;
                background-color: white;
                border-radius: 8px;
                padding: 7.5px;
                border: none;
                position: relative;
                width: 100%;
                // margin-top: -7.5px;
                .downloadIcon{
                    // position: absolute;
                    // right: 0;
                    // width: 100%;
                    border: none;
                    background-color: white;
                    cursor: pointer;
                }
            }
            .optionsWrapper{
                position: absolute;
                z-index: 1;
                padding: 15px;
                width: 250px;
                height: fit-content;
                background-color: white;
                color: black;
                // margin-top: 15px;
                right: 22.5px;
                border-radius: 15px;
                font-family: var(--mavenpro);
                box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
                .options{
                    margin-top:7.5px;
                    font-size: 15.5px;
                    border: none;
                    background-color: transparent;
                    font-family: var(--mavenpro);
                }
            }
        }
    }
    .posSummaryInsights{
        display: grid;
        grid-template-columns: 75% 25%;
        margin-top: 15px;
    }
    .invoiceReport{
        // margin-top: 25px;
        border: 1px soild black;
        // width: fit-content;
        height: fit-content;
        align-self: center;
        border-radius: 5px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px;
        font-family: var(--mavenpro);
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
        .reportDetails{
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;
            // background-color: #DDDDDD;
            background-color: transparent;
            border-radius: 10px;
            .invoiceDetailsWrapper{
                display: flex;
                flex-direction: column;
                width: 100%;
                .invoiceHeader{
                    font-size: 17px;
                    font-family: var(--mavenpro);
                    margin-bottom: 15px;
                    font-weight: 500;
                }
                .invoiceDetails{
                    position: relative;
                    display: grid;
                    grid-template-columns: 45% 35% 20%;
                    font-size: 15px;
                    font-weight: 400;
                    width: 100%;
                    height: fit-content;
                    background-color:transparent;
                    color: black;
                    .orderDetails{
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                    .customInvoice{
                        // margin-left: 25px;
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                    .GSTDetails{
                        // margin-left: 25px;
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                }
            }
            .posHotelWrapper{
                display: flex;
                flex-direction: column;
                // margin-top: 25px;
                width: 100%;
                position: relative;
                .hotelName{
                    font-size: 17px;
                    text-transform: uppercase;
                    font-weight: 500;
                    font-family: var(--mavenpro);
                }
                .hDetailWrapper{
                    // display: flex;
                    // flex-direction: row;
                    // flex-wrap: wrap;
                    display: grid;
                    grid-template-columns: 45% 35% 20%;
                    margin-top: 10px;
                    font-family: var(--mavenpro);
                    // justify-content: space-between;
                    position: relative;
                    width: 100%;
                    .hotelDetails{
                        display: flex;
                        flex-direction: column;
                        font-size: 15px;
                        font-weight: regular;
                        // border: 1px solid #F0D8D8;
                        border-radius: 12px;
                        // width: 500px;
                        width: 100%;
                        color: black;
                    }   
                    .hotelDetailHeader{
                        font-family: var(--mavenpro);
                        font-weight: 500;
                        font-size: 15px;
                    }
                    .hotelDetailVal{
                        font-family: var(--mavenpro);
                        margin-top: 7px;
                        font-size: 15px;
                    }
                }

            }
            .divider{
                position: relative;
                width: 100%;
                border-top: 1px solid #DDDDDD;
                margin-top: 15px;
                margin-bottom: 15px;
            }
            .orderWrapper{
                // margin-top: 15px;
                display: flex;
                flex-direction: column;
                .orderHeader{
                    font-size: 17px;
                    font-weight: 500;
                }
                .orderDetailsWrapper{
                    display: grid;
                    grid-template-columns: 45% 35% 20%;
                    .orderDetails{
                        margin-top: 10px;
                        display: flex;
                        flex-direction: column;
                        .orderDetailHeader{
                            font-weight: 500;
                            font-family: var(--mavenpro);
                            font-size: 15px;
                        }
                        .orderDetailVal{
                            margin-top: 7px;
                            font-size: 15px;
                            font-family: var(--mavenpro);
                        }
                    }
                }   
            }
        }
        .invoiceActionWrapper{
            display: flex;
            flex-direction: row;
            align-self: flex-end;
            margin-top: 25px;
            margin-right: 25px;
            .removeButton{
                background-color: transparent;
                border: 1.5px solid #DDDDDD;
                font-family: var(--mavenpro);
                // text-decoration: underline;
                font-size: 15px;
                padding: 8px 12px 8px 12px;
                border-radius: 7px;
                font-weight: 500;
                margin-top: auto;
                margin-bottom: auto;
            }
            .addCustomWrapper{
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 25px;
                .addCustomDots{
                    position: relative;
                    .openOptionsBtn{
                        font-family: var(--mavenpro);
                        display: flex;
                        flex-direction: column;
                        border: none;
                        background-color: transparent;
                        .bsIcons{
                            border: none;
                            padding: 5px;
                            border-radius: 8px;
                            background-color: transparent;

                        }
                        // .circularShape{
                        //     border: none;
                        //     border-radius: 50%;
                        //     width: 4.5px;
                        //     height: 5px;
                        //     background-color: black;
                        //     margin-top: 2.5px;
                        // }
                    }
                    .optionsWrapper{
                        position: absolute;
                        z-index: 1;
                        padding: 15px;
                        width: 250px;
                        height: fit-content;
                        background-color: white;
                        color: black;
                        margin-top: 15px;
                        right: 22.5px;
                        border-radius: 15px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        font-family: var(--mavenpro);
                        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
                        .options{
                            margin-top:7.5px;
                            font-size: 15.5px;
                            border: none;
                            background-color: transparent;
                            font-family: var(--mavenpro);
                            text-align: left;
                        }
                    }
                }
            }
        }
        .invoiceTable{
            // max-height: 450px;
            overflow-y: scroll;
            // margin-top: -45px;
            .actions{
                display: flex;
                flex-direction: row;
                .invoiceSearch{
                    margin-bottom: 25px;
                    border-color: 1px solid #DDDDDD;
                }
            }

        }
        .invoiceTable::-webkit-scrollbar{
            display: none;
        }
        .horizontalSeparator{
            width: 100%;
            border-top: 0.5px solid #e0e0e0;
            margin-top: 25px;
            margin-bottom: 25px;
        }
    }
    .cartSummaryWrapper{
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
        border-radius: 5px;
        font-family: var(--mavenpro);
        justify-content: space-between;
        padding: 15px;
        margin-left: 25px;
        height: fit-content;
        background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
        overflow: hidden;
        .invoiceSummaryReport{
            // float: right;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            position: relative;
            width: 100%;
            // justify-content: space-between;
            .summaryWrapper{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 7.5px;
                font-family: var(--mavenpro);
                font-size: 16px;
                position: relative;
                width: 100%;
                .summaryHeader{
                    font-weight: regular;
                    margin-top: auto;
                    margin-bottom: auto;
                    // color: #A69797;
                    color: white;
                }
                .summaryValue{
                    // margin-left: 15px;
                    font-weight: 500;
                    margin-top: auto;
                    margin-bottom: auto;
                    text-align: right;
                    color: white;
                }
            }
            .completeOrderButton{
                margin-top: 40px;
                margin-bottom: 15px;
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                font-weight: 500;
                .settleDuesBtn{
                    width: 47.5%;
                    position: relative;
                    height: 50px;
                    border-radius: 5px;
                    border: 1px solid #DDDDDD;
                    color: white;
                    background-color: transparent;
                }
                .completeOrderBtn{
                    width: 47.5%;
                    position: relative;
                    height: 50px;
                    font-family: var(--mavenpro);
                    border: none;
                    border-radius: 5px;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    // background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
                    background-color: white;
                    color: black;
                }   
            }
            .finishOrderWrapper{
                display: flex;;
                flex-direction: column;
                position: relative;
                width: 100%;
                .settleDuesBtn{
                    position: relative;
                    width: 100%;
                    border: 1px solid #DDDDDD;
                    background-color: transparent;
                    font-family: var(--mavenpro);
                    font-size: 15px;
                    color: white;
                    margin-top: 15px;
                    height: 50px;
                    border-radius: 5px;
                }
                .ctaWrapper{
                    margin-top: 25px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .cancelFinishOrder{
                        border: none;
                        background-color: none;
                        font-family: var(--mavenpro);
                        font-size: 15px;
                        font-weight: 500;
                        background-color: transparent;
                        color: white;
                        background-color: black;
                        border-radius: 5px;
                        height: 50px;
                        width: 47.5%;
                    }
                    .completeOrderBtn{
                        width: 47.5%;
                        position: relative;
                        height: 50px;
                        font-family: var(--mavenpro);
                        border: none;
                        border-radius: 5px;
                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                        // background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
                        background-color: white;
                        color: black;
                    }
                }
            }
            
        }
    }

}


// custom item sidebar
.addCustomItemWrapper{
    // padding: 25px;
    font-family: var(--mavenpro);
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    align-items: center;
    .addCustomHeader{
        font-family: var(--mavenpro);
        font-size: 18px;
        text-align: center;
        margin-top:12px;
        font-weight: 500;
    }
    .customPOSInvoiceDivider{
        border-top: 1px solid #DDDDDD;
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
        width: 100%;
    }
    .addCustomItemFormWrapper{
        margin-top: 12px;
        align-items: center;
        position: relative;
        width: 100%;
        margin-top: 45px;
        margin-bottom: 55px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .customItem{
            margin-bottom: 25px;
        }
    }
    .addCustomItemButton{
        margin-top: 45px;
        margin-left: 45px;
        display: flex;
        flex-direction: row;
        .cancelButton{
            background-color: transparent;
            color: black;
            text-decoration:underline;
            font-family: var(--mavenpro);
            width: 250px;
            font-size: 15.5px;
            font-weight: 500;
            border: none;
            text-align: left;
        }
        .addCustomBtn{
            background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
            font-family: var(--mavenpro);
            font-size: 17px;
            color: white;
            border: none;
            border-radius: 12px;
            width: 200px;
            height: 55px;
            font-weight: 500;
        }
    }
}

// discount sidebar
.addDiscountItemWrapper{
    font-family: var(--mavenpro);
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    align-items: center;
    .addDiscountHeader{
        font-family: var(--mavenpro);
        font-size: 18px;
        text-align: center;
        margin-top:12px;
        font-weight: 500;
    }
    .customPOSInvoiceDivider{
        border-top: 1px solid #DDDDDD;
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
        width: 100%;
    }
    .addDiscountItemFormWrapper{
        margin-top: 12px;
        align-items: center;
        position: relative;
        width: 100%;
        margin-top: 45px;
        margin-bottom: 55px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .customItem{
            margin-bottom: 25px;
        }
    }
    .addDiscountButtonWrapper{
        margin-top: 45px;
        margin-left: 45px;
        display: flex;
        flex-direction: row;
        .cancelButton{
            background-color: transparent;
            color: black;
            text-decoration:underline;
            font-family: var(--mavenpro);
            width: 250px;
            font-size: 15.5px;
            font-weight: 500;
            border: none;
            text-align: left;
        }
        .addCustomBtn{
            background-image: linear-gradient(-150deg, #1ab394, #1a91ae);
            font-family: var(--mavenpro);
            font-size: 17px;
            color: white;
            border: none;
            border-radius: 12px;
            width: 200px;
            height: 55px;
            font-weight: 500;
        }
    }
}

// settle dues modal
.settleDuesModalWrapper{
    overflow-y: scroll;
    margin-bottom: 25px;
}
.settleDuesModal{
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: none;
    font-family: var(--mavenpro);
    font-size: 15px;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
    width: 35%;
    position: absolute;
    top: 20%;
    left: 30%;
    overflow-y: auto;
    .settleDuesHeader{
        font-weight: 500;
        padding-bottom: 15px;
        border-bottom: 1px solid #DDDDDD;
    }
    .settleDuesContent{
        margin-top: 25px;

    }
    .settleDuesAccordian{
        background-color: transparent;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        font-family: var(--mavenpro);
        font-size: 15px;
        padding: 8px;
        overflow: hidden;
    }
    .accordianSummary{
        background-color: transparent;
    }
    .settleDuesBtnWrapper{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 35px;
        padding-top: 15px;
        border-top: 1px solid #DDDDDD;
    }
}

.cancelBtn{
    font-family: var(--mavenpro);
    font-size: 15px;
    font-weight: 500;
    border: none;
    background-color: transparent;
}
.submitBtn{
    font-family: var(--mavenpro);
    font-size: 15px;
    font-weight: 500;
    border: none;
    background-image: var(--backgroundImg);
    border-radius: 5px;
    color: white;
    height: 45px;
    padding: 10px;
}

// submitting cart modal
.submitWarningModal{
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: none;
    font-family: var(--mavenpro);
    font-size: 15px;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
    width: 35%;
    position: absolute;
    top: 20%;
    left: 30%;
    overflow-y: auto;
    .submitWarningModalHeader{
        font-weight: 500;
        text-align: center;
        padding-bottom: 15px;
        border-bottom: 1px solid #DDDDDD;
    }
    .submitWarningContent{
        margin-top: 25px;
        font-size: 15px;
        text-align: center;
    }
    .confirmationBtnWrappers{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        
    }
}